export const storeInLocalStroage = () => {
  clearStoreInLocalStroage();
  localStorage.setItem("allVendorData", JSON.stringify([]));
};

export const updateStoreInLocalStroage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getValueFromLocalStroage = (key) => {
  return JSON.parse(localStorage.getItem(key)) || null;
};

export const clearStoreInLocalStroage = () => {
  let vendorList = getValueFromLocalStroage("allVendorData");
  if (vendorList?.length > 0) {
    for (let i = 0; i < vendorList.length; i++) {
      localStorage.removeItem(vendorList[i]);
    }
  }
  localStorage.removeItem("allVendorData");
};
