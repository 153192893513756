import React from "react";
import * as XLSX from "xlsx";
const DownloadExcelSheet = ({ cardTitle, teamsLabelwithPrice }) => {
  // console.log(teamsLabelwithPrice);
  const downLoadExcelSheet = () => {
    const teamsData = teamsLabelwithPrice?.map((team) => [
      team.label,
      team.price,
    ]);

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Team Name", "Price"],
      ...teamsData,
    ]);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "TeamsData");

    // Save the workbook to a file
    XLSX.writeFile(workbook, `${cardTitle}.csv`);

    console.log("Excel file created successfully.");
  };

  return (
    <>
      <button
        style={{ background: "#22C55E" }}
        className="w-full font-semibold text-black rounded-md p-2 "
        onClick={downLoadExcelSheet}
      >
        Export to Excelsheet
      </button>
    </>
  );
};

export default DownloadExcelSheet;
