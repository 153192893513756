import React, { useEffect, useState } from "react";
import { urls } from "../../utils/urls";
import newRequest from "../../utils/newRequest";
import Modal from "./UpdateAdModal";
import { RiDeleteBin5Line, RiEdit2Fill, RiEye2Fill, RiEyeCloseFill, RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import ViewModal from "./ViewAdModal";

const AdCard = ({
  _id,
  title,
  company,
  duration,
  price,
  description,
  position,
  adType,
  redirectionURL,
  imageURL,
  setReloadData,
  thirdPartyImageUrl,
}) => {

  const [isDeleteLoading, setDeleteLoading] = useState(false)
  const handleDelete = async () => {
    setDeleteLoading(true)
    try {
      const result = await newRequest.delete(
        `/ad/${_id}`, {
          data: { imageURL }, // Include the imagePath in the request body
        }
      );
      if (result.data.success) {
        toast.success("Ad deleted Successfully!");
        // setDeleteLoading(false)
      }
      //  else {
      //   toast.error(result.data.message);
      // }
      //   Enable form submission after processing
      // setDisabled(false);
      // setShowAddAds(false);
      setReloadData(prevData => !prevData)
    } catch (error) {
      console.error("Error submitting form:", error);
      // Enable form submission after processing
      // setDisabled(false);
    } finally {
      setTimeout(1000,()=>{
        setDeleteLoading(false)
      })
      
    }
  }
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isOpenViewModal, setIsOpenViewModal] = useState(false);

  const openViewModal = () => {
    setIsOpenViewModal(true);
  };

  const closeViewModal = () => {
    setIsOpenViewModal(false);
  };
  console.log("imageURL: " ,imageURL)
  // console.log("isDeleteLoading",isDeleteLoading)

  useEffect(() => {
    if (isOpen || isOpenViewModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, isOpenViewModal]);


  return (
      // isDeleteLoading ? <div className="px-10 border h-[1rem] "> <Loader/> </div> :
      <div
        style={{
          // position: "relative",
          borderRadius: "12px",
          border: `${"1px solid #E7E4E4"}`,
          background: "#EFEFEF",
          boxShadow: "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
        }}
        className="overflow-hidden px-6 pt-4 pb-6 rounded-xl shadow-2xl "
      >
        <div>
          {imageURL && (
            <div className="flex justify-center text-center">
              <img
                src={`${urls.baseImageURL}uploads/ads/${imageURL}`}
                alt="Placeholder"
                width={"100px"}
                height={"80px"}
                className="mb-4 rounded-md"
              />
            </div>
          )}
          <h2 className="text-xl font-bold mb-2 h-[1.5rem]">Title: {title?.length>12?title.slice(0,12)+"...": title}</h2>
          <p className="text-gray-600 mb-2 h-[1.5rem]">Position: {position}</p>

          <p className="text-gray-600 mb-2  h-[1.5rem]">Company: {company?.length>12?company.slice(0,12)+"...": company}</p>
          <p className="text-gray-600 mb-2">Duration: {duration}</p>
          <p className="text-gray-800 font-bold mb-2">Price: {price}</p>
          <p className="text-gray-700 mb-4">Description: {description?.length>40?description.slice(0,40)+"...": description}</p>
          <p className="text-gray-700 mb-4">Ad Type: {adType}</p>
          <a href={redirectionURL} className="text-blue-500 hover:underline">
            Ad For Site
          </a>
          <div className="my-3 flex ">
          <RiEyeFill
            className="text-[#22C55E] cursor-pointer ml-[10px]"
            size={25}
            onClick={openViewModal}
          />
          <RiEdit2Fill
            className="text-[gray] cursor-pointer ml-[10px]"
            size={25}
            onClick={openModal}
          />
          {!isDeleteLoading && <RiDeleteBin5Line
              className="text-[red] cursor-pointer ml-[10px]"
              size={25}
              onClick={handleDelete}
          />}
          
            {/* <button className="text-gray-700 mb-4 bg-[#22C55E] px-2 py-1 text-white mx-1 rounded" onClick={openModal}>Update</button>
            <button className="text-gray-700 mb-4 bg-red-500 px-2 py-1 text-white mx-1 rounded" onClick={handleDelete}>Delete</button> */}
          </div>
          {/* <button onClick={openModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Open Modal
          </button> */}
          <Modal isOpen={isOpen} onClose={closeModal} formData1={{ _id, title, company, duration, price, description, position, adType, redirectionURL, imageURL:null, thirdPartyImageUrl}} setReloadData = {setReloadData} />
          <ViewModal onClose = {closeViewModal} isOpen = {isOpenViewModal} formData = {{ _id, title, company, duration, price, description, position, adType, redirectionURL, imageURL, thirdPartyImageUrl}} />
        </div>
      </div>
  );
};

export default AdCard;
