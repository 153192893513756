import React from "react";
import { useNavigate } from "react-router-dom";

const HomeScrapperNavigator = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap justify-center gap-8">
      <button
        onClick={() => {
          navigate("/after-clicking-button");
        }}
        className="bg-green-500 text-black font-bold px-4 py-2 rounded cursor-pointer"
      >
        RunScrapper
      </button>
      <button
        onClick={() => {
          navigate("/after-clicking-upload-excelsheet");
        }}
        className="bg-green-500 text-black font-bold px-4 py-2 rounded cursor-pointer"
      >
        ETL Via Excelsheet
      </button>
      <button
        onClick={() => {
          navigate("/website-cards-upload-excelsheet");
        }}
        className="bg-green-500 text-black font-bold px-4 py-2 rounded cursor-pointer"
      >
        Bulk Upload Via Scrapped Cards
      </button>
      <button
        onClick={() => {
          navigate("/manage-ad");
        }}
        className="bg-green-500 text-black font-bold px-4 py-2 rounded cursor-pointer"
      >
        Manage Ads
      </button>
      <button
        onClick={() => {
          navigate("/bulk-pload-via-excelsheet");
        }}
        className="bg-green-500 text-black font-bold px-4 py-2 rounded cursor-pointer"
      >
        Bulk Upload Via Excelsheet
      </button>
    </div>
  );
};

export default HomeScrapperNavigator;
