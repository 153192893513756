import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { urls } from "./../utils/urls";
import newRequest from "./../utils/newRequest";
import PriceSection from "./../components/PriceSection";
import years from "./../utils/years";
import sports from "./../utils/sports";
import brands from "./../utils/brands";
import teams from "./../utils/teams";
import Header from "../components/Header";
import RichTextEditor from "../components/RichTextEditor";

export default function EditSportCard() {
  const [newImage, setNewImage] = useState("");
  const [deleteImage, setDeleteImage] = useState(false);
  const [sportCard, setSportCard] = useState({
    image: "",
    sport: "",
    year: 0,
    brand: "",
    team: "",
    boxOrCaseBreak: "",
    pytOrRandomBreak: "",
    breaker: "",
    price: "",
    description: "",
    deleteImage: deleteImage ? "true" : "false",
    prices: [
      {
        vendor: "",
        product: "",
        price: "0",
        seller: "",
        date: "",
        sellerLink: "",
      },
    ],
  });
  const [dropdownData, setDropdownData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  // get the specific card
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await newRequest.get(`${urls.getSportCard}${id}`);
        setSportCard(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCards();
  }, []);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setDeleteImage(true);
    setSportCard((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const handleDescriptionChange = (value) => {
    setSportCard((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "pytOrRandomBreak") {
      if (value === "random") {
        setSportCard((prevState) => ({
          ...prevState,
          pytOrRandomBreak: value,
          team: "",
        }));
      } else {
        setSportCard((prevState) => ({
          ...prevState,
          pytOrRandomBreak: value,
        }));
      }
    } else if (name === "brand") {
      // Update the brand value for all products
      const updatedPrices = sportCard.prices.map((price) => ({
        ...price,
        product: value,
      }));

      setSportCard((prevState) => ({
        ...prevState,
        brand: value,
        prices: updatedPrices,
      }));
    } else {
      setSportCard((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handlePriceChange = (event, index) => {
    const { name, value } = event.target;
    const updatedPrices = [...sportCard.prices];
    updatedPrices[index][name] = value;

    setSportCard((prevState) => ({
      ...prevState,
      prices: updatedPrices,
    }));
  };

  const addPriceSection = () => {
    setSportCard((prevState) => ({
      ...prevState,
      prices: [
        ...prevState.prices,
        {
          vendor: "",
          product: "",
          price: 0,
          seller: "",
          date: "",
          sellerLink: "",
        },
      ],
    }));
  };

  const removePriceSection = (indexToRemove) => {
    setSportCard((prevState) => ({
      ...prevState,
      prices: prevState.prices.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await newRequest.patch(`${urls.updateSportCard}${id}`, sportCard, config);

      alert("Sport card updated successfully!");
      navigate("/sport-card-list");
    } catch (error) {
      console.error(error);
      alert("Error adding sport card.");
    }
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await newRequest.get("/admin/dropdown");
        setDropdownData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <section className="py-8">
        <form
          className="flex flex-col relative mx-auto my-50 w-1/2  p-20"
          onSubmit={handleSubmit}
          enctype="multipart/form-data"
        >
          <h2 className="text-xl mb-2 font-bold">Edit Sport Card</h2>
          <div className="w-[600px] h-[300px] border-2 border-gray-300 py-10 px-20 flex justify-center items-center mb-8">
            {sportCard.image && (
              <img
                src={`${urls.baseImageURL}uploads/${sportCard?.image}`}
                alt="Image Preview"
                style={{ width: "200px", height: "auto" }}
              />
            )}
          </div>
          <label htmlFor="image">Upload Card Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileInputChange}
          />
          <div className="w-[600px]">
            <div className="flex gap-4 mb-4 w-full ">
              <div className="w-1/3">
                <label className="font-bold mb-5 mt-5" htmlFor="sport">
                  Sport:
                </label>
                <select
                  className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full p-2"
                  required
                  id="sport"
                  name="sport"
                  value={sportCard.sport}
                  onChange={handleInputChange}
                >
                  <option value="" disabled className="text-lg text-gray-700">
                    Select
                  </option>
                  {dropdownData?.sports?.map((data) => (
                    <option
                      value={data?.sport}
                      key={data?._id}
                      className="text-lg text-gray-700"
                    >
                      {data?.sport}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/3">
                <label className="font-bold mb-5 mt-5" htmlFor="year">
                  Year:
                </label>
                <input
                  className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
                  type="number"
                  id="year"
                  name="year"
                  value={sportCard.year}
                  onChange={handleInputChange}
                  list="year-list"
                />
                <datalist id="year-list">
                  {years.map((y) => (
                    <option key={y} value={y} />
                  ))}
                </datalist>
              </div>
              <div className="w-1/3">
                <label className="font-bold mb-5 mt-5" htmlFor="brand">
                  Brand:
                </label>
                <select
                  required
                  className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
                  id="brand"
                  name="brand"
                  value={sportCard.brand}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Please Select a Brand
                  </option>
                  {dropdownData?.brands?.map((data, i) => (
                    <option key={data?._id} value={data?.brand}>
                      {data?.brand}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex gap-4 mb-4 w-full">
              {sportCard.pytOrRandomBreak !== "random" && (
                <div className="w-1/3">
                  <label className="font-bold mb-5 mt-5" htmlFor="team">
                    Team:
                  </label>
                  <select
                    className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full p-2"
                    id="team"
                    name="team"
                    value={sportCard.team}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {dropdownData?.teams?.map((data) => (
                      <option key={data?._id} value={data?.team}>
                        {data?.team}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="w-1/3">
                <label className="font-bold mb-5 mt-5" htmlFor="boxOrCaseBreak">
                  Box or Case Break:
                </label>
                <select
                  required
                  className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full p-2"
                  id="boxOrCaseBreak"
                  name="boxOrCaseBreak"
                  value={sportCard.boxOrCaseBreak}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Please Select a Option
                  </option>
                  {dropdownData?.boxOrCaseBreaks?.map((data) => (
                    <option key={data?._id} value={data?.boxOrCaseBreak}>
                      {data?.boxOrCaseBreak}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-1/3">
                <label
                  className="font-bold mb-5 mt-5"
                  htmlFor="pytOrRandomBreak"
                >
                  PYT or Random Break:
                </label>
                <select
                  className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full p-2"
                  required
                  id="pytOrRandomBreak"
                  name="pytOrRandomBreak"
                  value={sportCard.pytOrRandomBreak}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Please Select a Option
                  </option>
                  {dropdownData?.pytOrRandomBreaks?.map((data) => (
                    <option key={data?._id} value={data?.pytOrRandomBreak}>
                      {data?.pytOrRandomBreak.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="flex gap-4 justify-start w-full mb-4w-full">
              <div className="w-[30%]">
                <label className="font-bold mb-5 mt-5" htmlFor="breaker">
                  Breaker:
                </label>
                <input
                  className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
                  required
                  id="breaker"
                  name="breaker"
                  value={sportCard.breaker}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-[70%]">
                <label className="font-bold mb-5 mt-5" htmlFor="breaker">
                  Price:
                </label>
                <input
                  className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
                  required
                  id="price"
                  name="price"
                  value={sportCard.price}
                  onChange={handleInputChange}
                  placeholder="$"
                />
              </div>
            </div> */}
            <div className="my-4">
              <label htmlFor="breaker">Description:</label>
              {/* <textarea
                className="w-[600px] h-[100px] border-2 border-gray-300 p-2"
                cols="30"
                rows="5"
                name="description"
                id="description"
                value={sportCard.description}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              ></textarea> */}
              <RichTextEditor
                onChange={handleDescriptionChange}
                value={sportCard.description}
              />
              <label
                className="font-bold mb-5 mt-5"
                style={{ paddingTop: "50px" }}
              >
                Prices:
              </label>
              {sportCard.prices.map((price, index) => (
                <PriceSection
                  key={index}
                  price={price}
                  index={index}
                  handlePriceChange={handlePriceChange}
                  addPriceSection={addPriceSection}
                  removePriceSection={removePriceSection}
                  isLastPrice={index === sportCard.prices.length - 1}
                  sportCard={sportCard}
                  dropdownData={dropdownData}
                />
              ))}
              <button
                className="px-2 py-2 bg-[#0033FF] text-white my-4"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
