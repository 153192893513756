import React, { useState } from "react";
import newRequest from "./../utils/newRequest";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
export default function AddDropdownOptions() {
  const [newOptions, setNewOptions] = useState([]);
  const [optionInput, setOptionInput] = useState("");

  const handleOptionInputChange = (event) => {
    setOptionInput(event.target.value);
  };

  const handleAddOption = () => {
    if (optionInput) {
      setNewOptions([...newOptions, optionInput]);
      setOptionInput("");
    }
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...newOptions];
    updatedOptions.splice(index, 1);
    setNewOptions(updatedOptions);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await newRequest.post("/api/options", {
        options: newOptions,
      });
      setNewOptions([]);
      setOptionInput("");
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="max-w-500 mx-auto p-20 bg-gray-100 border border-gray-300 rounded-lg">
        <h2 className="mt-0 text-2xl">Add New Options</h2>
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <label className="block mb-4 text-lg" htmlFor="options">
            Options:
          </label>
          <div className="mb-5">
            {newOptions.map((option, index) => (
              <div
                className="flex items-center p-4 bg-gray-200 rounded-lg mb-4"
                key={index}
              >
                <span>{option}</span>
                <button
                  type="button"
                  className="ml-4 bg-red-600 text-white py-2 px-4 rounded-md"
                  onClick={() => handleDeleteOption(index)}
                >
                  X
                </button>
              </div>
            ))}
          </div>

          <div className="flex flex-col mb-5">
            <input
              type="text"
              id="options"
              name="options"
              value={optionInput}
              onChange={handleOptionInputChange}
              className="flex-1 p-4 border border-gray-300 rounded-md mr-4"
            />
            <button
              type="button"
              onClick={handleAddOption}
              className="bg-green-500 text-white border-0 h-10 px-4 py-1 rounded-md"
            >
              Add
            </button>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white border-0 px-4 py-2 rounded-md"
          >
            Save
          </button>
        </form>
      </div>
    </>
  );
}
