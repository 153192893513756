import React, { useEffect, useState } from 'react';
import newRequest from '../../utils/newRequest';
import { toast } from "react-toastify";
import Loader from '../../components/Loader';
import RedCrossBtnSvg from "./RedCrossBtnSvg";

const ViewModal = ({ isOpen, onClose, formData}) => {

    if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center my-5 mx-[36px]">
      <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>
        <div className="modal-container bg-white w-11/12 md:w-90 h-full mx-auto my-4 md:my-0 rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content">
                <div className="modal-header flex justify-between items-center">
                    {/* <h1 className="modal-title text-2xl font-bold">Update Ad</h1> */}
                    {/* <button onClick={onClose} className="modal-close">
                    <span className='bg-red-500 px-4 py-2 rounded text-white text-xl'>X</span>
                    </button> */}
                </div>
                <div
                    // style={{
                    //     position: "relative",
                    //     borderRadius: "12px",
                    //     border: "1px solid #E7E4E4",
                    //     background: "#EFEFEF",
                    //     boxShadow:
                    //     "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
                    // }}
                    //   className="p-10 rounded-xl shadow-2xl "
                    className="items-center mx-4 md:mx-20  px-6 mt-2 pt-2  gap-8"
                    >
                    <button className="text-white font-semibold text-2xl flex ml-auto px-3 rounded my-3 " onClick={onClose}>
                        <RedCrossBtnSvg/>
                    </button>
                    <h1 className="text-green-500 font-semibold text-2xl text-center mb-8">
                        View Advertisement
                    </h1>
                    <form>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Input fields */}
                        <div>
                            <label className="block mb-2 font-bold" htmlFor="description">
                            Title:
                            </label>
                            <p className="w-full px-4 py-2 mb-4 border rounded-md min-h-[2.6rem]">
                            {formData.title}
                            </p>
                        </div>
                       
                        <div>
                            <label className="block mb-2 font-bold" htmlFor="company">
                            Company:
                            </label>
                            <p className="w-full px-4 py-2 mb-4 border rounded-md min-h-[2.6rem]">
                                {formData.company}
                            </p>
                            
                        </div>
                        <div>
                            <label className="block mb-2 font-bold" htmlFor="duration">
                            Duration:
                            </label>
                            <input
                                value={formData.duration}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                disabled
                            />
                        </div>

                        <div>
                            <label className="block mb-2 font-bold" htmlFor="price">
                            Price:
                            </label>
                            <input
                            value={formData.price}
                            className="w-full px-4 py-2 mb-4 border rounded-md"
                            disabled
                            />
                        </div>

                        <div>
                            <label className="block mb-2 font-bold" htmlFor="description">
                            Description:
                            </label>
                            <p className="w-full px-4 py-2 mb-4 border rounded-md min-h-[2.6rem]">
                                {formData.description}
                            </p>
                        </div>

                        <div>
                            <label className="block mb-2 font-bold" htmlFor="position">
                            Position:
                            </label>
                            <input  
                            value={formData.position}
                            className="w-full px-4 py-2 mb-4 border rounded-md"
                            disabled
                            />
                        </div>


                        <div>
                            <label className="block mb-2 font-bold" htmlFor="adType">
                                Type:
                            </label>
                            <input  
                                value={formData.adType}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                disabled
                            />
                        </div>


                        <div>
                            <label
                            className="block mb-2 font-bold"
                            htmlFor="redirectionURL"
                            >
                            Site Url:
                            </label>
                            <p className="w-full px-4 py-2 mb-4 border rounded-md min-h-[2.5rem]">
                                {formData.redirectionURL}
                            </p>
                        </div>

                        <div>
                            <label
                            className="block mb-2 font-bold"
                            htmlFor="thirdPartyImageUrl"
                            >
                            Third Party Image Url:
                            </label>
                            <p className="w-full px-4 py-2 mb-4 border rounded-md min-h-[2.5rem]">
                                {formData.thirdPartyImageUrl}
                            </p>
                        </div>

                        {/* Add similar input fields for other form elements */}

                        {/* File input for image */}
                        {/* { formData.adType!=="Ebay" &&
                            <div>
                            <label className="block mb-2 font-bold" htmlFor="image">
                                Image:
                            </label>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                accept="image/*"
                            />
                            </div>
                        } */}
                        
                        </div>
                        {/* <div className='flex justify-center'>
                            <button onClick={(e)=>{e.preventDefault(); onClose()}} className="bg-red-500 text-white ml-5 px-12 font-bold py-1 my-6  rounded-md">Close</button>
                        </div> */}
                    </form>
                </div>
                {/* )  */}
            </div>
        </div>
    </div>
  );
};

export default ViewModal;
