import React, { useState } from "react";
import { toast } from "react-toastify";
import CardInfoModal from "./CardInfoModal";
import newRequest from "../../utils/newRequest";
import { urls } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
const UploadCardsViaExcelButton = ({ teams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Function to handle modal open/close
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to handle form submission data
  const handleFormSubmit = async (formData) => {
    // Handle the submitted data here, such as sending it to an API or updating state
    console.log("Team are here :::", teams);
    // console.log("console log is here:::::", formData?.image);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    console.log("Teams are here :::::: ", teams);
    const res = await newRequest.post(
      `${urls.addBulkSportsCardsLink}`,
      { ...formData, teams },
      config
    );

    toast.success("Sport card added successfully!");
    navigate("/sport-card-list");
    // console.log("res is here :::::: ", res);
  };

  const downLoadExcelSheet = () => {
    toggleModal();
  };

  return (
    <>
      <button
        className="bg-green-500 text-black font-semibold h-10 px-4 py-2 rounded cursor-pointer"
        onClick={downLoadExcelSheet}
      >
        Bulk Upload Cards
      </button>
      <div className="relaitve flex items-center justify-center">
        <CardInfoModal
          isOpen={isModalOpen}
          onClose={toggleModal}
          onSubmit={handleFormSubmit}
        />
      </div>
    </>
  );
};

export default UploadCardsViaExcelButton;
