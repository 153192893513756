import React from "react";
import "./cardloader.css"; // Import the CSS file where you put the styles

export const LoaderSpinner = () => {
  return (
    <div className="loader-container">
      <svg
        className="spinnercard"
        width="81"
        height="80"
        viewBox="0 0 81 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_34_819)">
          <path
            d="M40.5 0C42.6875 0 44.4531 1.76562 44.4531 3.95312V16.4688C44.4531 18.6562 42.6875 20.4219 40.5 20.4219C38.3125 20.4219 36.5469 18.6562 36.5469 16.4688V3.95312C36.5469 1.76562 38.3125 0 40.5 0Z"
            fill="#22C55E"
          />
          <path
            d="M12.2188 11.7187C13.7656 10.1719 16.2656 10.1719 17.7969 11.7187L26.6406 20.5625C28.1875 22.1094 28.1875 24.6094 26.6406 26.1406C25.0938 27.6875 22.5938 27.6875 21.0625 26.1406L12.2188 17.2969C10.6719 15.7656 10.6719 13.2656 12.2188 11.7187ZM0.5 40C0.5 37.8125 2.26562 36.0469 4.45312 36.0469H16.9688C19.1562 36.0469 20.9219 37.8125 20.9219 40C20.9219 42.1875 19.1562 43.9531 16.9688 43.9531H4.45312C2.26562 43.9531 0.5 42.1875 0.5 40ZM12.2188 68.2812C10.6719 66.7344 10.6719 64.2344 12.2188 62.7031L21.0625 53.8594C22.6094 52.3125 25.1094 52.3125 26.6406 53.8594C28.1875 55.4062 28.1875 57.9062 26.6406 59.4375L17.7969 68.2812C16.2656 69.8281 13.7656 69.8281 12.2188 68.2812Z"
            fill="#5F8B6F"
          />
          <path
            d="M40.5 80C38.3125 80 36.5469 78.2344 36.5469 76.0469V63.5312C36.5469 61.3438 38.3125 59.5781 40.5 59.5781C42.6875 59.5781 44.4531 61.3438 44.4531 63.5312V76.0469C44.4531 78.2344 42.6875 80 40.5 80ZM68.7812 68.2812C67.2344 69.8281 64.7344 69.8281 63.2031 68.2812L54.3594 59.4375C52.8125 57.8906 52.8125 55.3906 54.3594 53.8594C55.9063 52.3125 58.4062 52.3125 59.9375 53.8594L68.7812 62.7031C70.3281 64.2344 70.3281 66.75 68.7812 68.2812ZM80.5 40C80.5 42.1875 78.7344 43.9531 76.5469 43.9531H64.0313C61.8438 43.9531 60.0781 42.1875 60.0781 40C60.0781 37.8125 61.8438 36.0469 64.0313 36.0469H76.5469C78.7344 36.0469 80.5 37.8125 80.5 40ZM68.7812 11.7188C70.3281 13.2656 70.3281 15.7656 68.7812 17.2969L59.9375 26.1406C58.3906 27.6875 55.8906 27.6875 54.3594 26.1406C52.8125 24.5938 52.8125 22.0938 54.3594 20.5625L63.2031 11.7188C64.7344 10.1719 67.2344 10.1719 68.7812 11.7188Z"
            fill="#22C55E"
          />
        </g>
        <defs>
          <clipPath id="clip0_34_819">
            <rect
              width="80"
              height="80"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
