import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import newRequest from "../utils/newRequest";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import HomeScrapperNavigator from "../Scrapper/components/ButtonScrapper";

const AddNewList = () => {
  const [inputs, setInputs] = useState({
    sport: "",
    brand: "",
    team: "",
    boxOrCaseBreak: "",
    pytOrRandomBreak: "",
    breaker: "",
  });
  const [dropdownData, setDropdownData] = useState([]);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSave = async (inputName) => {
    try {
      const inputValue = inputs[inputName];

      const payload = {
        [inputName]: inputValue,
      };

      // Check if the input value is empty
      if (!inputValue) {
        console.log(`${inputName} cannot be empty.`);
        setError(
          `${
            inputName.charAt(0).toUpperCase() + inputName.slice(1)
          } Cannot Be Empty.`
        );
        setTimeout(() => {
          setError(``);
        }, 3000);
        return;
      }

      const response = await newRequest.post(
        `/admin/${inputName}/add`,
        payload
      );
      setError("");
      console.log("RESPONSE DATA :", response);
      setDropdownData(response.data);
      setInputs({ ...inputs, [inputName]: "" });
    } catch (error) {
      console.error(`Error adding ${inputName}:`, error);
    }
  };

  const handleDelete = async (itemId, itemType) => {
    try {
      // Ask for confirmation
      const confirmed = window.confirm(
        "Are you sure you want to delete this item?"
      );

      if (!confirmed) {
        return; // If not confirmed, exit the function
      }

      // Make a DELETE request to the server
      const response = await newRequest.delete(
        `/admin/${itemType}/delete/${itemId}`
      );

      setDropdownData(response.data);

      console.log(`Item with ${itemId} deleted from category ${itemType}`);
      // Implement any further actions if needed
    } catch (error) {
      console.error(`Error deleting item with id ${itemId}: ${error}`);
      // Implement error handling if needed
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await newRequest.get("/admin/dropdown");
        setDropdownData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };
    fetchDropdownData();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <HomeScrapperNavigator/>
      <div className="flex justify-center relative">
        <div className="absolute top-10 left-0 right-0 bottom-0 flex items-center justify-center">
          <h1 className="text-red-500 font-bold text-2xl">{error}</h1>
        </div>
      </div>

      <div className="mt-20 px-10">
        <form>
          <div className="flex">
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="sport"
              >
                Sport:
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="sport"
                  id="sport"
                  value={inputs.sport}
                  onChange={handleChange}
                  required
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("sport")}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="brand"
              >
                Brand:
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="brand"
                  id="brand"
                  value={inputs.brand}
                  onChange={handleChange}
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("brand")}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="team"
              >
                Team:
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="team"
                  id="team"
                  value={inputs.team}
                  onChange={handleChange}
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("team")}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="boxOrCaseBreak"
              >
                Box or Case Break:
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="boxOrCaseBreak"
                  id="boxOrCaseBreak"
                  value={inputs.boxOrCaseBreak}
                  onChange={handleChange}
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("boxOrCaseBreak")}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="pytOrRandomBreak"
              >
                PYT or Random Break:
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="pytOrRandomBreak"
                  id="pytOrRandomBreak"
                  value={inputs.pytOrRandomBreak}
                  onChange={handleChange}
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("pytOrRandomBreak")}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-col mr-2">
              <label
                className="mb-2 uppercase font-bold text-gray-700"
                htmlFor="breaker"
              >
                Breakers
              </label>
              <div className="flex gap-2 items-center justify-center">
                <input
                  className="p-1 border rounded"
                  type="text"
                  name="breaker"
                  id="breaker"
                  value={inputs.breaker}
                  onChange={handleChange}
                />
                <button
                  className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                  type="button"
                  onClick={() => handleSave("breaker")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="w-full pt-10">
          <div className="flex space-x-4 justify-between ">
            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">Sports:</h2>
              {dropdownData?.sports?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.sport}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "sport")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>

            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">Brands:</h2>
              {dropdownData?.brands?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.brand}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "brand")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>

            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">Teams:</h2>
              {dropdownData?.teams?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.team}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "team")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>

            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">Box or Case Breaks:</h2>
              {dropdownData?.boxOrCaseBreaks?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.boxOrCaseBreak}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "boxOrCaseBreak")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>

            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">
                PYT or Random Breaks:
              </h2>
              {dropdownData?.pytOrRandomBreaks?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.pytOrRandomBreak}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "pytOrRandomBreak")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>

            <div className="text-center  border border-black p-5">
              <h2 className="text-2xl font-bold mb-4 ">Breakers:</h2>
              {dropdownData?.breaker?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-center items-center mb-5"
                >
                  <p className="text-lg border w-[150px] border-b-black">
                    {item?.breaker}
                  </p>
                  <button
                    className="text-red-500 hover:text-red-700 ml-2"
                    onClick={() => handleDelete(item?._id, "breaker")}
                  >
                    <AiOutlineDelete size={25} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewList;
