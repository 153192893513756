import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import newRequest from "../../utils/newRequest";
import AdCard from "./AdCard";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import RedCrossBtnSvg from "./RedCrossBtnSvg";

const AddAd = () => {
  const [formData, setFormData] = useState({
    title: "",
    company: "",
    duration: "",
    price: "",
    description: "",
    position: "",
    adType:"",
    redirectionURL: "",
    image: null, // Add image state
    thirdPartyImageUrl: null,
  });
  const [disabled, setDisabled] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [showAddAds, setShowAddAds] = useState(false);
  const [reloadData, setReloadData] = useState(false)

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

 
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("setFormData is here:::: ", formData);
    try {
      //   Perform form validation here if needed
      //   Disable form submission while processing
      setDisabled(true);
      //   Example of submitting data

      const config = {
        // headers: { "Content-Type": formData.adType==="Custom" ?  "multipart/form-data" : "application/json"},
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      };
      // console.log(formData)
      const fd = new FormData();
      fd.append("title", formData.title);
      fd.append("company", formData.company);
      fd.append("duration", formData.duration);
      fd.append("price", formData.price);
      fd.append("description", formData.description);
      fd.append("position", formData.position);
      fd.append("asType", formData.asType);
      fd.append("adType", formData.adType);
      fd.append("redirectionURL", formData.redirectionURL);
      fd.append("thirdPartyImageUrl", formData.thirdPartyImageUrl);
      fd.append("image", formData.image); // Append the image here


      const result = await newRequest.post("ad", fd, config);
      if (result.data.success) {
        toast.success(result.data.message);
        // Clear form after successful submission if needed
        setFormData({
          title: "",
          company: "",
          duration: "",
          price: "",
          description: "",
          position: "",
          asType: "",
          redirectionURL: "",
          image: null,
          thirdPartyImageUrl: null,
        });
      } else {
        toast.error(result.message);
      }
      //   Enable form submission after processing
      setDisabled(false);
      setShowAddAds(false);
    } catch (error) {
      // console.log(error.response.data.message)
      toast.error(error.response.data.message);
      // Enable form submission after processing
      setDisabled(false);
    } finally {
      setDisabled(false)
      closeModal();
      setReloadData(prevData => !prevData)
    }
  };

  const [isDataAvailable, setDataAvailable] = useState(false)
  useEffect(() => {
    const callMe = async () => {
      const result = await newRequest.get("ad");
      if (result.data.success) {
        setCardList(result.data.data);
        setDataAvailable(true)
      }
    };
    callMe();
  }, [showAddAds, reloadData]);

  const handleAdChange = (event) => {
    setFormData({
      ...formData,
      adType: event.target.value,
    });
  };
  const handlePositionChange = (event) => {
    setFormData({
      ...formData,
      position: event.target.value,
    });
  };

  
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [itemsPerPages, setItemsPerPages] = useState(8);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * itemsPerPage;
  const currentPageData = cardList.slice(offset, offset + itemsPerPage);
  const startIndex = pageNumber * itemsPerPage + 1;
  return (
    <>
    {!isDataAvailable ? <Loader /> : (
      <>
        {isOpen ? ( 
          <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center my-5 mx-[36px]">
            <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>
            <div className="modal-container bg-white w-11/12 md:w-90 h-full mx-auto my-4 md:my-0 rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content">
                <div className="modal-header flex justify-between items-center">
                  {/* <h1 className="modal-title text-2xl font-bold">Update Ad</h1> */}
                  {/* <button onClick={onClose} className="modal-close">
                    <span className='bg-red-500 px-4 py-2 rounded text-white text-xl'>X</span>
                  </button> */}
                </div>
                <div
                    style={{
                      position: "relative",
                      borderRadius: "12px",
                      border: "1px solid #E7E4E4",
                      background: "#EFEFEF",
                      boxShadow:
                        "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
                    }}
                    //   className="p-10 rounded-xl shadow-2xl "
                    // className="items-start mx-36 w-4/5 px-6  mt-12 pt-10 pr-6 gap-8"
                    className="modal-body"
                  >
                    {disabled && <button className="text-white font-semibold text-2xl flex ml-auto px-3 rounded my-3 " onClick={closeModal}>
                          <RedCrossBtnSvg/>
                        </button> }
                    { disabled ? <Loader /> : 
                      <div
                              // style={{
                              //     position: "relative",
                              //     borderRadius: "12px",
                              //     border: "1px solid #E7E4E4",
                              //     background: "#EFEFEF",
                              //     boxShadow:
                              //     "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
                              // }}
                              //   className="p-10 rounded-xl shadow-2xl "
                              className="items-center mx-4 md:mx-20  px-6  mt-2 pt-2  gap-8 mb-12"
                              >
                        <button className="text-white font-semibold text-2xl flex ml-auto px-3 rounded my-3 " onClick={closeModal}>
                          <RedCrossBtnSvg/>
                        </button>
                        <h1 className="text-[#22C55E] font-semibold text-2xl text-center mb-8">
                          Add Advertisements
                        </h1>
                        <form onSubmit={handleSubmit}>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Input fields */}
                            <div>
                              <label className="block mb-2 font-bold" htmlFor="title">
                                Title:
                              </label>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter title"
                              />
                            </div>
                            <div>
                              <label className="block mb-2 font-bold" htmlFor="company">
                                Company:
                              </label>
                              <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter company"
                              />
                            </div>
                            <div>
                              <label className="block mb-2 font-bold" htmlFor="duration">
                                Duration:
                              </label>
                              <input
                                type="number"
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter duration in days"
                              />
                            </div>

                            <div>
                              <label className="block mb-2 font-bold" htmlFor="price">
                                Price:
                              </label>
                              <input
                                type="text"
                                id="price"
                                name="price"
                                value={formData.price}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter price"
                              />
                            </div>

                            <div>
                              <label className="block mb-2 font-bold" htmlFor="description">
                                Description:
                              </label>
                              <input
                                type="text"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter description"
                              />
                            </div>

                            {/* <div>
                              <label className="block mb-2 font-bold" htmlFor="position">
                                Position:
                              </label>
                              <input
                                type="text"
                                id="position"
                                name="position"
                                value={formData.position}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter position you want to display at"
                              />
                            </div> */}
                            <div>
                              <label className="block mb-2 font-bold" htmlFor="position">
                                Position:
                              </label>
                              <select value={formData.position} onChange={handlePositionChange} required className="w-full px-4 py-2 mb-4 border rounded-md">
                              {/* <select> */}
                                <option value="">Select Position</option>
                                <option value="Navigation Bar">Navigation Bar</option>
                                <option value="Top 20 Featured Products ABOVE">Top 20 Featured Products ABOVE</option>
                                <option value="Inside Top 20 Featured Products">Inside Top 20 Featured Products</option>
                                {/* <option value="Top 20 Featured Products RIGHT">Top 20 Featured Products RIGHT</option> */}
                                <option value="Release Date ABOVE">Release Date ABOVE</option>
                                <option value="Inside Release Date">Inside Release Date</option>
                                {/* <option value="Release Date LEFT">Release Date LEFT</option> */}
                                {/* <option value="Release Date RIGHT">Release Date RIGHT</option> */}
                                <option value="Footer ABOVE">Footer ABOVE</option>
                                {/* Add more options as needed */}
                              </select>
                            </div>


                            <div>
                              <label
                                className="block mb-2 font-bold"
                                htmlFor="redirectionURL"
                              >
                                Type:
                              </label>
                              {/* <input
                                type="text"
                                id="redirectionURL"
                                name="redirectionURL"
                                value={formData.redirectionURL}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter url where you want to go"
                              /> */}
                              <select value={formData.adType} onChange={handleAdChange} required className="w-full px-4 py-2 mb-4 border rounded-md">
                              {/* <select> */}
                                <option value="">Select Ad Type</option>
                                <option value="Custom">Custom</option>
                                <option value="Ebay">Ebay</option>
                                {/* <option value="Mixed">Mixed</option> */}
                                {/* Add more options as needed */}
                              </select>
                            </div>


                            <div>
                              <label
                                className="block mb-2 font-bold"
                                htmlFor="redirectionURL"
                              >
                                Site Url:
                              </label>
                              <input
                                type="text"
                                id="redirectionURL"
                                name="redirectionURL"
                                value={formData.redirectionURL}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter url where you want to go"
                              />
                            </div>


                            <div>
                              <label
                                className="block mb-2 font-bold"
                                htmlFor="thirdPartyImageUrl"
                              >
                                Third Party Image Url:
                              </label>
                              <input
                                type="text"
                                id="thirdPartyImageUrl"
                                name="thirdPartyImageUrl"
                                value={formData.thirdPartyImageUrl}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 mb-4 border rounded-md"
                                placeholder="Enter third party image url"
                              />
                            </div>

                            {/* Add similar input fields for other form elements */}

                            {/* File input for image */}
                            { formData.adType!=="Ebay" &&
                              <div>
                                <label className="block mb-2 font-bold" htmlFor="image">
                                  Image:
                                </label>
                                <input
                                  type="file"
                                  id="image"
                                  name="image"
                                  onChange={handleImageChange}
                                  className="w-full px-4 py-2 mb-4 border rounded-md"
                                  accept="image/*"
                                />
                              </div>
                            }
                            
                          </div>
                            <div className="flex justify-center mt-6">
                              <button
                                type="submit"
                                className="bg-green-500 text-black px-4 md:px-32 font-bold py-2 mb-3  rounded-md"
                              >
                                Add Advertisement
                              </button>
                            </div>
                            {/* <div className='flex justify-center'>
                                <button onClick={closeModal} className="bg-red-500 text-white ml-5 px-12 font-bold py-1 mb-8  rounded-md">Close</button>
                            </div> */}
                          {/* </div> */}
                        </form>
                      </div>
                    }
                </div>
                {/* <div className="modal-footer mt-4 flex justify-end">
                  <button onClick={onClose} className="px-4 py-2 bg-gray-400 text-white rounded-md">Close</button>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div>
           <div className="flex flex-col md:flex-row items-center justify-between mx-6 lg:mx-20 rounded px-6 lg:px-20 py-6 mt-20 bg-gray-100">
              <h1 className="text-black font-semibold text-2xl text-center lg:text-left mb-2 md:mb:0">
                All Advertisements
              </h1>
              <button
                className="bg-green-500 text-black font-bold px-4 py-2 rounded-md lg:px-6 lg:py-3"
                onClick={() => openModal(true)}
              >
                Add Advertisement
              </button>
          </div>
              {/* <div className="flex flex-wrap items-start px-10 lg:px-20 pt-20">
                <h1 className="text-black font-semibold text-2xl text-start mb-8">
                  All Advertisements
                </h1>
              </div> */}

              <div
                className="flex flex-wrap items-center justify-center px-10 lg:px-20 pt-10 gap-10 mb-10"
                // style={{ overflowY: "auto", height: `calc(100vh - ${350}px)` }}
              >
                {currentPageData.length > 0 && (
                  <div className="flex flex-wrap justify-center">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-11 justify-center">
                    {currentPageData.map((item, index) => {
                      return <AdCard key={item._id} {...item} setReloadData = {setReloadData} />;
                    })}
                  </div>
                  </div>
                )}
              </div>
            </div>
            
        )}

            <div className="mb-12 flex justify-center">
              <ReactPaginate
                forcePage = {pageNumber}
                pageCount={Math.ceil(cardList.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination flex justify-end"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
          </div>
      </>
      
    )}
    </>
  );
};

export default AddAd;
