import React from "react";
import "../styles/loader.css"; // Assuming you have other custom styles in this file

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen -mb-20">
       <div className="spinner"></div>
    </div>
  );
};

export default Loader;
