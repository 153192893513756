import React from "react";

const GreenCheckSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42_860)">
        <path
          d="M17.0426 10.4908L17.0374 10.4934C16.4204 10.9665 16.159 11.7717 16.3786 12.5168L16.3812 12.5221C16.7315 13.7063 15.8636 14.901 14.6296 14.9324H14.6244C13.8453 14.9533 13.1604 15.45 12.9016 16.1846V16.1872C12.4885 17.3532 11.0821 17.8107 10.0651 17.1074C9.43362 16.6762 8.59883 16.6537 7.93445 17.1074H7.93185C6.91492 17.808 5.50842 17.3532 5.09797 16.1846C4.97129 15.826 4.73877 15.5144 4.43116 15.2908C4.12355 15.0672 3.75533 14.9422 3.37518 14.9324H3.36994C2.13603 14.901 1.26805 13.7063 1.61838 12.522L1.62099 12.5168C1.84054 11.7717 1.57912 10.9665 0.962193 10.4933L0.956955 10.4907C-0.0234121 9.73779 -0.0234121 8.26337 0.956955 7.51047L0.962193 7.50787C1.57912 7.0347 1.84054 6.22948 1.61838 5.48441V5.47917C1.26542 4.29494 2.13599 3.10019 3.36994 3.06883H3.37518C4.1516 3.04791 4.83915 2.55119 5.09797 1.8166V1.81399C5.50839 0.648037 6.91492 0.190549 7.93185 0.893814H7.93445C8.57493 1.33562 9.42195 1.33562 10.0651 0.893814C11.0923 0.184467 12.4909 0.655033 12.9016 1.81399V1.8166C13.1604 2.54858 13.8453 3.04794 14.6244 3.06883H14.6296C15.8636 3.10019 16.7315 4.29494 16.3812 5.47917L16.3786 5.48441C16.159 6.22948 16.4204 7.0347 17.0374 7.50787L17.0426 7.51047C18.023 8.26337 18.023 9.73783 17.0426 10.4908Z"
          fill="#3EB655"
        />
        <path
          d="M8.99962 13.8697C11.6891 13.8697 13.8694 11.6895 13.8694 9C13.8694 6.31052 11.6891 4.13026 8.99962 4.13026C6.31014 4.13026 4.12988 6.31052 4.12988 9C4.12988 11.6895 6.31014 13.8697 8.99962 13.8697Z"
          fill="#8BD399"
        />
        <path
          d="M12.739 5.8828C11.8967 5.18987 10.819 4.77337 9.64424 4.77337C6.95479 4.77337 4.77334 6.95481 4.77334 9.64427C4.77334 10.819 5.18984 11.8967 5.88273 12.739C4.81254 11.8463 4.13086 10.5034 4.13086 8.99993C4.13086 6.31044 6.31044 4.13089 8.99989 4.13089C10.5034 4.13089 11.8463 4.81257 12.739 5.8828Z"
          fill="black"
          fillOpacity="0.0627451"
        />
        <path
          d="M7.84123 10.9064L6.76436 9.7607C6.48234 9.46061 6.49689 8.98878 6.79688 8.70675C7.09687 8.42438 7.56891 8.43953 7.85069 8.73941L8.36475 9.28609L10.5498 6.78867C10.8207 6.47873 11.2917 6.4473 11.6019 6.71853C11.9119 6.98976 11.9432 7.46071 11.6721 7.77065L8.94553 10.8867C8.65507 11.2184 8.14245 11.227 7.84123 10.9064Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_860">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenCheckSvg;
