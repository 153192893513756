import React from "react";

export default function PriceSection({
  price,
  index,
  handlePriceChange,
  addPriceSection,
  removePriceSection,
  isLastPrice,
  sportCard,
  dropdownData,
}) {
  console.log(price?.vendor);
  console.log(dropdownData);

  // function formatDateToEasternTimeZone(dateString) {
  //   const date = new Date(dateString);
  //   const easternTimeOffset = -4; // Eastern Time is UTC-4 during Eastern Daylight Time (EDT) or UTC-5 during Eastern Standard Time (EST)
  //   const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  //   const easternTime = new Date(utcTime + easternTimeOffset * 3600000);
  //   const formattedDate = easternTime.toISOString().split("T")[0];
  //   return formattedDate;
  // }

  return (
    <div key={index} className="mt-6">
      <p className="font-bold mb-2">Breaker {index + 1}</p>
      <div className="flex gap-4 w-full">
        <div className="w-1/3">
          <label className="font-bold mb-5 mt-5" htmlFor={`vendor${index}`}>
            Name
          </label>
          <select
            required
            className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
            id={`vendor${index}`}
            name="vendor"
            value={price.vendor}
            onChange={(event) => handlePriceChange(event, index)}
          >
            <option value="">Select Name</option>
            {dropdownData?.breaker
              ?.sort((a, b) => a.breaker.localeCompare(b.breaker))
              .map((data) => (
                <option key={data?._id} value={data?.breaker}>
                  {data?.breaker}
                </option>
              ))}
          </select>
        </div>

        <div className="w-1/3">
          <label className="font-bold mb-5 mt-5" htmlFor={`product${index}`}>
            Product:
          </label>
          <input
            required
            className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
            type="text"
            id={`product${index}`}
            name="product"
            value={price.product}
            onChange={(event) => handlePriceChange(event, index)}
            placeholder={`Brand`}
            // placeholder="Enter Price"
          />
        </div>

        <div className="w-1/3">
          <label className="font-bold mb-5 mt-5" htmlFor={`price${index}`}>
            Price:
          </label>
          <input
            className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
            required
            type="number"
            id={`price${index}`}
            name="price"
            value={price.price}
            onChange={(event) => handlePriceChange(event, index)}
            placeholder="Enter Price"
          />
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <div className="w-1/3">
          <label className="font-bold mb-5 mt-5" htmlFor={`date${index}`}>
            Date:
          </label>
          <br />
          <input
            required
            className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
            type="date"
            id={`date${index}`}
            name="date"
            value={
              price.date && new Date(price.date).toISOString().split("T")[0]
            }
            onChange={(event) => handlePriceChange(event, index)}
            placeholder="Enter date"
          />
        </div>
        <div className="w-[70%]">
          <label className="font-bold mb-5 mt-5" htmlFor={`sellerLink${index}`}>
            Seller Link:
          </label>
          <input
            className="pt-2 pb-2 rounded-md border border-gray-300 box-border w-full p-2"
            required
            type="text"
            id={`sellerLink${index}`}
            name="sellerLink"
            value={price.sellerLink}
            onChange={(event) => handlePriceChange(event, index)}
            placeholder="Enter Seller Link"
          />
        </div>
      </div>

      <div className="flex gap-4">
        {isLastPrice && (
          <button
            className="px-2 py-2 bg-[#00D62B] text-white my-4"
            type="button"
            onClick={addPriceSection}
          >
            Add Vendor +
          </button>
        )}
        {sportCard.prices.length > 1 && (
          <button
            type="button"
            className="px-2 py-2 bg-[#00D62B] text-white my-4"
            onClick={() => removePriceSection(index)}
            style={{ backgroundColor: "red" }}
          >
            Remove Vendor
          </button>
        )}
      </div>
    </div>
  );
}
