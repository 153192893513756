import React from "react";

const CrossButtonSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="30px"
      width="30px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <circle style={{ fill: "#22C55E" }} cx="256" cy="256" r="256" />
      <path
        style={{ fill: "#22C55E" }}
        d="M150.2,368.6l141,141c115.2-15.8,205.9-108.3,219.1-224.2L367.9,142.9L150.2,368.6z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M368.5,337.4l-82.8-82.8l82.8-82.8c7.8-7.8,7.8-20.5,0-28.3s-20.5-7.8-28.3,0l-82.8,82.8l-82.8-82.8  c-7.8-7.8-20.5-7.8-28.3,0s-7.8,20.5,0,28.3l82.8,82.8l-82.8,82.8c-7.8,7.8-7.8,20.5,0,28.3c3.9,3.9,9,5.9,14.1,5.9s10.2-2,14.1-5.9  l82.8-82.8l82.8,82.8c3.9,3.9,9,5.9,14.1,5.9s10.2-2,14.1-5.9C376.3,357.9,376.3,345.2,368.5,337.4z"
      />
    </svg>
  );
};

export default CrossButtonSvg;
