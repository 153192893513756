import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import {
  clearStoreInLocalStroage,
  getValueFromLocalStroage,
} from "../../GlobalStateManagement";
import CardInfoModal from "./CardInfoModal";
import newRequest from "../../utils/newRequest";
import { urls } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
const UploadCards = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teams, setTeams] = useState(false);
  const navigate = useNavigate();

  // Function to handle modal open/close
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to handle form submission data
  const handleFormSubmit = async (formData) => {
    // Handle the submitted data here, such as sending it to an API or updating state
    // console.log("Form Data:", formData);
    // console.log("console log is here:::::", formData?.image);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await newRequest.post(
      `${urls.addBulkSportCard}`,
      { ...formData, teams },
      config
    );
    toast.success("Sport card added successfully!");
    navigate("/sport-card-list");
    // console.log("res is here :::::: ", res);
  };

  // Helper Function
  function getPrice(array, team) {
    const matchingTeam = array.find(
      (t) => t.label.toLowerCase().trim() === team.toLowerCase()
    );
    return matchingTeam ? matchingTeam.price.replace("$", "") : 0;
  }

  const downLoadExcelSheet = () => {
    let vendorList = getValueFromLocalStroage("allVendorData");
    let vendorListWithAssociatedTeams = [];
    let vendorSequence = [];
    for (let i = 0; i < vendorList.length; i++) {
      let data = getValueFromLocalStroage(vendorList[i]);
      vendorListWithAssociatedTeams.push(data);
      vendorSequence.push(vendorList[i]);
    }
    if (vendorList.length > 0) {
      // Your code here if the length of at least one data is greater than 0
      const allTeams = [
        ...new Set(
          [...vendorListWithAssociatedTeams.flat()].map((team) =>
            team.label.toLowerCase().trim()
          )
        ),
      ];
      // Create an array of headers
      const headers = ["Team Name", ...vendorSequence];

      // Create an array for the teams data
      const teamsData = [
        headers,
        ...allTeams.map((team) => {
          let vendorSequenceList = [];
          for (let i = 0; i < vendorListWithAssociatedTeams.length; i++) {
            vendorSequenceList.push(
              getPrice(vendorListWithAssociatedTeams[i], team)
            );
          }
          return [team, ...vendorSequenceList];
        }),
      ];
      setTeams(teamsData);
      toggleModal();
    } else {
      toast.error("Select any of the card!!!");
    }
  };

  return (
    <>
      <button
        className="bg-green-500 text-black font-semibold h-10 px-4 py-2 rounded cursor-pointer"
        onClick={downLoadExcelSheet}
      >
        Upload Cards
      </button>
      <div className="relaitve flex items-center justify-center">
        <CardInfoModal
          isOpen={isModalOpen}
          onClose={toggleModal}
          onSubmit={handleFormSubmit}
        />
      </div>
    </>
  );
};

export default UploadCards;
