import { useEffect, useState } from "react";
import Loader from "../../components/ScraperButtonLoader";
import RoundedPillLabel from "./SportPill";
import { newRequestToScrappingDB } from "../../utils/newRequest";
import { LoaderSpinner } from "./CardLoaderSpinner";
import GreenCheckSvg from "./greenCheckSvg";

export const ScrapperButtonWithLoader = ({
  onClick,
  color,
  label,
  isLoading,
  statusMessage,
  sports,
}) => {
  let { loading, dataFetched } = isLoading;

  const [selectedPillText, getSelectedPillText] = useState([]);
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "12px",
        border: "1px solid #E7E4E4",
        background: "#EFEFEF",
        boxShadow: "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
      }}
      className="p-10 rounded-xl shadow-2xl "
    >
      <h1 className="flex text-black justify-center text-lg font-semibold leading-7">
        {label}
      </h1>
      <h1 className="text-black text-md font-medium leading-7 mt-2">
        Select one or multiple sports to scrap
      </h1>
      <div className="mt-6 flex justify-start flex-wrap gap-4">
        {sports.length > 0 ? (
          sports.map((item) => {
            return (
              <RoundedPillLabel
                key={item.sport} // add a unique key
                label={item.sport}
                getSelectedPillText={getSelectedPillText}
              />
            );
          })
        ) : (
          <h3> No sports added yet</h3>
        )}
      </div>
      {!loading && dataFetched && (
        <div
          className={`mt-4 w-full flex gap-1 justify-center items-center text-center text-md font-medium text-green-500`}
        >
          <GreenCheckSvg />
          Scrapped Successfully!!!
        </div>
      )}

      {!loading && dataFetched == false && (
        <div
          className={`mt-4 w-full flex gap-1 justify-center items-center text-center text-md font-medium text-red-500`}
        >
          Scraper Failed! Please retry in 30 minutes!!!
        </div>
      )}
      <div
        className={`${
          dataFetched ? "mt-4" : dataFetched == false ? "mt-4" : "mt-16"
        } relative flex justify-center`}
      >
        <button
          style={{ backgroundColor: "#22C55E" }}
          className={`text-black text-md font-semibold px-4 py-2 rounded-md cursor-pointer leading-7 w-3/4 `}
          onClick={() => {
            onClick(selectedPillText);
          }}
          disabled={loading}
        >
          Run Scrapper
        </button>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        >
          <LoaderSpinner />
          <div className={`text-white text-sm  font-semibold mt-2`}>
            {statusMessage}
          </div>
        </div>
      )}
    </div>
  );
};
