import axios from "axios";
import { urls } from "./urls";

const newRequest = axios.create({
  baseURL: "https://api.breakcomp.com/api/",
  // baseURL: "http://localhost:8800/api/",
  withCredentials: true,
});
newRequest.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // If the response has a 401 or 403 status, log out the user and redirect to the login page
    if (error.response && [401, 403].includes(error.response.status)) {
      logoutUser();
    }
    // If the response has another error status, just reject the promise with the error
    return Promise.reject(error);
  }
);

// Second Scrapping DB integration:::::::::::::::::::::
export const newRequestToScrappingDB = axios.create({
  baseURL: "https://scrapper.breakcomp.com/",
  // baseURL: "http://localhost:8954/",
  withCredentials: true,
});
newRequestToScrappingDB.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // If the response has a 401 or 403 status, log out the user and redirect to the login page
    if (error.response && [401, 403].includes(error.response.status)) {
      logoutUser();
    }
    // If the response has another error status, just reject the promise with the error

    return Promise.reject(error);
  }
);

const logoutUser = async () => {
  try {
    await newRequest.post(`${urls.adminlogout}`);
    localStorage.removeItem("comparison-user");
    localStorage.setItem("searchCount", 0);
    window.location.href = "/login";
  } catch (err) {
    console.log(err);
  }
};

export default newRequest;
