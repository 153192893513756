import React, { useEffect, useState } from "react";
import RoundedPillLabel from "./SportPill";
import Header from "../../components/Header";
import { newRequestToScrappingDB } from "../../utils/newRequest";
import { toast } from "react-toastify";

const Sports = ({ getAllSports }) => {
  const [addSport, setAddSport] = useState("");
  const [sports, setSports] = useState([]);
  const [addedNewSport, setAddedNewSport] = useState([]);
  const [disabled, setDisabled] = useState(false);

  //  add a sport
  const addNewSportCall = async () => {
    if (addSport.length > 0) {
      setDisabled(true);
      const result = await newRequestToScrappingDB.post("sport/sports", {
        sport: addSport,
      });
      if (result.data.success) {
        setAddedNewSport((prev) => [...prev, addSport]);
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
      setDisabled(false);
    } else {
      toast.error("Please enter a valid support !!!");
    }
  };

  useEffect(() => {
    const callme = async () => {
      const result = await newRequestToScrappingDB.get("sport/sports");
      console.log(result);
      //   toast.success(result.data.message);
      setSports(result.data.data);
      getAllSports(result.data.data);
    };
    callme();
  }, [addedNewSport]);

  return (
    <>
      {/* <Header /> */}
      <div
        style={{
          position: "relative",
          borderRadius: "12px",
          border: "1px solid #E7E4E4",
          background: "#EFEFEF",
          boxShadow: "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
        }}
        className="p-10 rounded-xl shadow-2xl "
        class="flex flex-col items-start mx-36 w-4/5 px-6  mt-12 pt-10 pr-6 gap-8"
      >
        <h1 className="text-black font-semibold text-2xl ">
          Add Sport To Scrap
        </h1>
        <div className=" flex flex-wrap justify-center gap-6">
          <label className="pt-2 font-bold "> Add Sport: </label>
          <input
            style={{ background: "#EFEDED", border: "1px solid #D0D9EB" }}
            className="rounded-md gap-8 h-10 px-4"
            type="text"
            placeholder="Enter sport you want to scrap"
            onChange={(e) => setAddSport(e.target.value)}
            value={addSport}
          />
          <button
            style={{ background: "#22C55E" }}
            className="text-black text-lg font-medium rounded-lg px-8 cursor-pointer "
            disabled={disabled}
            onClick={addNewSportCall}
          >
            Add
          </button>
        </div>
        <div className="flex flex-wrap gap-4 justify-center mb-10">
          {sports.length > 0 ? (
            sports.map((item) => {
              return (
                <RoundedPillLabel
                  label={item.sport}
                  getSelectedPillText={() => {}}
                />
              );
            })
          ) : (
            <h3> No Sports added yet</h3>
          )}
        </div>
      </div>
    </>
  );
};

export default Sports;
