import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Header from "../../components/Header";
import { newRequestToScrappingDB } from "../../utils/newRequest";
import ScraperCard from "./ScrapperCard";
import Loader from "../../components/Loader";
import {
  clearStoreInLocalStroage,
  storeInLocalStroage,
} from "../../GlobalStateManagement";
import ExportCombineExcelSheet from "./ExportCombineExcelShet";

const UploadExelSheet = () => {
  const [excelData, setExcelData] = useState(null);
  const [selectedSource, setSelectedSource] = useState("ALL");
  const [cardList, setCardList] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [excelSheetName, setExcelSheetName] = useState("");

  const getListOfCards = async () => {
    clearStoreInLocalStroage();
    if (!selectedSource) {
      alert("Select website to search cards for!!!");
    }
    if (excelData?.length > 0) {
      setIsLoading(true);
      const result = await newRequestToScrappingDB.post(
        "cards/search-keywords",
        {
          data: excelData,
          website: selectedSource,
        }
      );
      setCardList(result?.data.data);
      setNoDataMessage(result?.data.data.length === 0 ? true : false);
      console.log(result);

      setIsLoading(false);
    } else {
      alert("First Upload the ExcelSheet");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const removeColumns = jsonData.shift();
        setExcelData(jsonData);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };

  useEffect(() => {
    storeInLocalStroage();
    return () => {
      clearStoreInLocalStroage();
    };
  }, [cardList]);

  return (
    <>
      <Header />
      <div className="flex flex-col items-start px-36 pt-10 pr-36 gap-10">
        <h1 className="text-black font-semibold leading-6 text-2xl">
          Upload filtered excelSheet for ETL operations.
        </h1>
        <div className="flex justify-center">
          <div className=" flex flex-wrap rounded-lg gap-8 text-gray-600 leading-4 items-center">
            <label className="text-black font-medium">Select Website:</label>
            <select
              value={selectedSource}
              onChange={handleSourceChange}
              className="border p-2 rounded-md"
            >
              <option value="ALL">All</option>
              <option value="MojoBreakerScrapper">Mojo Break</option>
              <option value="BlezBreakerScrapper">The Blez Break</option>
              <option value="JaspysCaseScrapper">Jaspyscase Break</option>
              <option value="LaytonSportCardScrapper">
                Layton Sports Card
              </option>
              <option value="BomberBreaksScrapper">Bomber break</option>
              <option value="FanaticsLiveScrapper">Fanatic Live</option>
              <option value="WhatNotScrapper">What Not</option>
            </select>
            <label className="text-lg  text-black font-medium ">
              Upload ExcelSheet:
            </label>
            <input
              type="file"
              onChange={handleFileUpload}
              className="rounded-md border border-solid- text-sm file:mr-4 file:py-2 file:px-6 file:rounded-md file:border-0 file:font-medium file:bg-violet-100 file:text-gray-600"
            />
            <button
              className="bg-green-500 text-white px-4 py-2 rounded cursor-pointer"
              onClick={getListOfCards}
            >
              Get List
            </button>
          </div>
        </div>
      </div>

      {cardList.length > 0 && (
        <div className="flex justify-end mt-10 mx-36">
          <div className=" flex flex-wrap rounded-lg gap-8 text-gray-600 leading-4 items-center">
            <input
              style={{ background: "#EFEDED", border: "1px solid #D0D9EB" }}
              className="rounded-md gap-8 h-10 px-4"
              type="text"
              placeholder="Enter name for excelsheet"
              onChange={(e) => setExcelSheetName(e.target.value)}
              value={excelSheetName}
            />
            <ExportCombineExcelSheet excelSheetName={excelSheetName} />
          </div>
        </div>
      )}

      {isLoading && <Loader />}
      {!isLoading && (
        <div
          className="flex flex-wrap items-start px-36 pt-10 gap-10 mb-10 scrollbar-custom"
          style={{ overflowY: "auto", height: `calc(100vh - ${350}px)` }}
        >
          {cardList.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-11">
              {cardList.map((item, index) => {
                return <ScraperCard key={item._id + index} {...item} />;
              })}
            </div>
          )}
          {cardList.length === 0 && noDataMessage && (
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-11">
              No Data For these Filters, Please change the Filters
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UploadExelSheet;
