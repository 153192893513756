import React, { useState } from "react";
import newRequest from "../utils/newRequest";
import { urls } from "../utils/urls";
import { useNavigate } from "react-router-dom";
import PriceSection from "../components/PriceSection";
import years from "../utils/years";
import sports from "./../utils/sports";
import brands from "./../utils/brands";
import { RiDeleteBin5Line } from "react-icons/ri";
import teams from "./../utils/teams";
import Header from "../components/Header";
import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RichTextEditor from "../components/RichTextEditor";

export default function AddSportCard() {
  const [sportCard, setSportCard] = useState({
    image: "",
    sport: "",
    year: "",
    brand: "",
    team: "",
    boxOrCaseBreak: "",
    pytOrRandomBreak: "pyt",
    breaker: "",
    price: "",
    description: "",
    prices: [
      {
        vendor: "",
        product: "",
        price: "",
        seller: "",
        date: "",
        sellerLink: "",
      },
    ],
  });
  const [dropdownData, setDropdownData] = useState({});
  const navigate = useNavigate();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSportCard((prevState) => ({
      ...prevState,
      image: file,
    }));
  };
  //  const editorConfiguration = {
  //     toolbar: {
  //       items: [
  //         "heading",
  //         "|",
  //         "bold",
  //         "italic",
  //         "underline",
  //         "strikethrough",
  //         "|",
  //         "bulletedList",
  //         "numberedList",
  //         "|",
  //         "link",
  //         "|",
  //         "undo",
  //         "redo",
  //       ],
  //     },
  //   };

  const handleInputChange = (event) => {
    // console.log("event: ", event);
    const { name, value } = event.target;
    if (name === "pytOrRandomBreak") {
      if (value === "random") {
        setSportCard((prevState) => ({
          ...prevState,
          pytOrRandomBreak: value,
          team: "",
        }));
      } else {
        setSportCard((prevState) => ({
          ...prevState,
          pytOrRandomBreak: value,
        }));
      }
    } else if (name === "brand") {
      // Update the brand value for all products
      const updatedPrices = sportCard.prices.map((price) => ({
        ...price,
        product: value,
      }));

      setSportCard((prevState) => ({
        ...prevState,
        brand: value,
        prices: updatedPrices,
      }));
    } else {
      setSportCard((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // const [description, setDescription] = useState("");

  const handleDescriptionChange = (value) => {
    setSportCard((prevState) => ({
      ...prevState,
      description: value,
    }));
  };
  // const handleDescriptionChange = (event, editor) => {
  //   const description = editor.getData();
  //   setSportCard((prevState) => ({
  //     ...prevState,
  //     description: description,
  //   }));
  // };

  const handlePriceChange = (event, index) => {
    const { name, value } = event.target;
    const updatedPrices = [...sportCard.prices];
    updatedPrices[index][name] = value;

    setSportCard((prevState) => ({
      ...prevState,
      prices: updatedPrices,
    }));
  };

  const addPriceSection = () => {
    setSportCard((prevState) => ({
      ...prevState,
      prices: [
        ...prevState.prices,
        {
          vendor: "",
          product: "",
          price: "",
          seller: "",
          date: "",
          sellerLink: "",
        },
      ],
    }));
  };

  const removePriceSection = (indexToRemove) => {
    setSportCard((prevState) => ({
      ...prevState,
      prices: prevState.prices.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(sportCard);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await newRequest.post(`${urls.addSportCard}`, sportCard, config);
      alert("Sport card added successfully!");
      navigate("/sport-card-list");
    } catch (error) {
      console.error(error);
      alert("Error adding sport card.");
    }
  };

  const handleImageRemove = () => {
    setSportCard((prevState) => ({
      ...prevState,
      image: "",
    }));
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await newRequest.get("/admin/dropdown");
        setDropdownData(response.data);
        console.log("dropdown data", response.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);

  return (
    <>
      <Header />
      <section className="py-8">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col  relative mx-auto my-50 w-1/2  p-20"
        >
          <h2 className="text-xl mb-2 font-bold">Upload Card Image</h2>
          {sportCard.image && (
            <div className="flex justify-center mb-[20px]">
              <img
                src={URL.createObjectURL(sportCard.image)}
                alt="Card Preview"
                style={{ width: "200px", height: "auto" }}
              />
              {/* <p onClick={handleImageRemove}>remove</p> */}
              <RiDeleteBin5Line
                className="text-[red] cursor-pointer ml-[10px]"
                size={25}
                onClick={handleImageRemove}
              />
            </div>
          )}
          {!sportCard.image && (
            <div className="w-[600px] h-[300px] border-2 border-gray-300 py-10 px-20 flex justify-center items-center mb-8">
              <label className="flex justify-center items-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Upload an Image, or&nbsp;
                    <span className="text-blue-600 underline">browse</span>
                  </span>
                </span>
                <input
                  className="hidden w-full p-4 mb-20 rounded-md border border-gray-300 box-border text-lg"
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleFileInputChange}
                  required
                />
              </label>
            </div>
          )}
          {/* <div className="add-new-sport">+</div> */}
          <div className="w-[600px]">
            <div className="flex gap-4 mb-4 w-full ">
              <div className="w-1/3">
                <label htmlFor="sport" className="font-bold mb-5 mt-5">
                  Sport:
                </label>
                <select
                  className=" pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full"
                  required
                  id="sport"
                  name="sport"
                  value={sportCard.sport}
                  onChange={handleInputChange}
                >
                  <option value="" disabled className="text-lg text-gray-700">
                    Select
                  </option>
                  {dropdownData?.sports
                    ?.sort((a, b) => a.sport.localeCompare(b.sport)) // Sort the data alphabetically
                    .map((data) => (
                      <option
                        value={data?.sport}
                        key={data?._id}
                        className="text-lg text-gray-700"
                      >
                        {data?.sport}
                      </option>
                    ))}
                </select>
              </div>
              <div className="w-1/3">
                <label htmlFor="year" className="font-bold mb-5 mt-5">
                  Year:
                </label>
                <input
                  className=" pt-2 pb-2 rounded-md border border-gray-300 box-border w-full"
                  type="number"
                  id="year"
                  name="year"
                  value={sportCard.year}
                  onChange={handleInputChange}
                  list="year-list"
                  placeholder="Enter Year"
                />
                <datalist id="year-list">
                  {years.map((y) => (
                    <option key={y} value={y} />
                  ))}
                </datalist>
              </div>
              <div className="w-1/3">
                <label htmlFor="brand" className="font-bold mb-5 mt-5">
                  Brand:
                </label>
                <select
                  className=" pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full"
                  required
                  id="brand"
                  name="brand"
                  value={sportCard.brand}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {dropdownData?.brands
                    ?.sort((a, b) => a.brand.localeCompare(b.brand))
                    .map((data, i) => (
                      <option key={data?._id} value={data?.brand}>
                        {data?.brand}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex gap-4 mb-4 w-full">
              {sportCard.pytOrRandomBreak !== "random" && (
                <div className="w-1/3">
                  <label className="font-bold mb-5 mt-5" htmlFor="team">
                    Team:
                  </label>
                  <select
                    className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full p-2"
                    id="team"
                    name="team"
                    value={sportCard.team}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {dropdownData?.teams
                      ?.sort((a, b) => a.team.localeCompare(b.team))
                      .map((data) => (
                        <option key={data?._id} value={data?.team}>
                          {data?.team}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="w-1/3">
                <label htmlFor="boxOrCaseBreak" className="font-bold mb-5 mt-5">
                  Box or Case Break:
                </label>
                <select
                  className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full"
                  // required
                  id="boxOrCaseBreak"
                  name="boxOrCaseBreak"
                  value={sportCard.boxOrCaseBreak}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {dropdownData?.boxOrCaseBreaks
                    ?.sort((a, b) =>
                      a.boxOrCaseBreak.localeCompare(b.boxOrCaseBreak)
                    )
                    .map((data) => (
                      <option key={data?._id} value={data?.boxOrCaseBreak}>
                        {data?.boxOrCaseBreak}
                      </option>
                    ))}
                </select>
              </div>
              <div className="w-1/3">
                <label
                  htmlFor="pytOrRandomBreak"
                  className="font-bold mb-5 mt-5"
                >
                  PYT or Random Break:
                </label>
                <select
                  className="pt-2 pb-2 text-lg text-gray-700 bg-white border border-gray-300 rounded-md w-full"
                  // required
                  id="pytOrRandomBreak"
                  name="pytOrRandomBreak"
                  value={sportCard.pytOrRandomBreak}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {dropdownData?.pytOrRandomBreaks
                    ?.sort((a, b) =>
                      a.pytOrRandomBreak.localeCompare(b.pytOrRandomBreak)
                    )
                    .map((data) => (
                      <option key={data?._id} value={data?.pytOrRandomBreak}>
                        {data?.pytOrRandomBreak.toUpperCase()}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* <div className="flex gap-4 justify-start w-full mb-4">
              <div className="w-1/3">
                <label htmlFor="breaker" className="font-bold mb-5 mt-5">
                  Breaker:
                </label>
                <br />
                <input
                  className="pt-2 pb-2 p-2 rounded-md border border-gray-300 box-border w-full"
                  required
                  id="breaker"
                  name="breaker"
                  value={sportCard.breaker}
                  onChange={handleInputChange}
                  placeholder="Enter Breaker"
                />
              </div>
              <div className="w-1/3">
                <label htmlFor="breaker" className="font-bold mb-5 mt-5">
                  Price:
                </label>
                <br />
                <input
                  className="pt-2 pb-2 p-2 rounded-md border border-gray-300 box-border w-full"
                  required
                  id="price"
                  name="price"
                  type="number"
                  value={sportCard.price}
                  onChange={handleInputChange}
                  placeholder="Enter price"
                />
              </div>
            </div> */}
            <div className="mb-4 ">
              <label htmlFor="breaker" className="font-bold mb-5 mt-5">
                Description:
              </label>
              {/* <textarea
                className="w-[600px] h-[100px] border-2 border-gray-300 p-2 mb-3"
                cols="30"
                rows="5"
                name="description"
                id="description"
                value={sportCard.description}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              ></textarea> */}

              {/* <CKEditor
                editor={ClassicEditor}
                data={sportCard.description}
                onChange={handleDescriptionChange}
                config={editorConfiguration}
              /> */}
              <RichTextEditor
                onChange={handleDescriptionChange}
                value={sportCard.description}
              />

              <label className="font-bold flex justify-center ">
                Breakers{" "}
              </label>
              {sportCard.prices.map((price, index) => (
                <PriceSection
                  key={index}
                  price={price}
                  index={index}
                  handlePriceChange={handlePriceChange}
                  addPriceSection={addPriceSection}
                  removePriceSection={removePriceSection}
                  isLastPrice={index === sportCard.prices.length - 1}
                  sportCard={sportCard}
                  dropdownData={dropdownData}
                />
              ))}
              <button
                className="px-2 py-2 bg-[#0033FF] text-white my-4"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        {/* 
        <div className="flex h-[300px] w-[600px] border-black border">
          <div className=" flex flex-col">
            <div className="h-[100px] w-[200px] border-red-500 border m-2"></div>
            <div className="h-[100px] w-[200px] border-red-500 border m-2"></div>
          </div>
          <div className="h-[215px] w-[600px] border-red-500 border m-2"></div>
        </div> */}
      </section>
    </>
  );
}
