import React, { useEffect, useState } from "react";
import DownloadExcelSheet from "./DownloadExcelSheet";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import CopyText from "./copyText";
import {
  getValueFromLocalStroage,
  updateStoreInLocalStroage,
} from "../../GlobalStateManagement";
import { toast } from "react-toastify";

const ScraperCard = ({
  sport,
  cardRegularPrice,
  cardTitle,
  cardTitleAnchor,
  scrapperName,
  teamsLabelwithPrice,
  vendor,
}) => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [websiteName, setWebsiteName] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  // clippath section

  const copyToClipboard = () => {
    // Assuming cardTitleAnchor contains the text you want to copy
    const textToCopy = cardTitleAnchor;

    // Create a temporary textarea element
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = textToCopy;

    // Append the textarea to the document
    document.body.appendChild(tempTextArea);

    // Select the text in the textarea
    tempTextArea.select();
    tempTextArea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(tempTextArea);

    // Update state to show copied feedback
    setIsCopied(true);

    // Reset the copied state after a short delay (optional)
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const checkTickConditions = (key, value) => {
    let data = getValueFromLocalStroage(key);
    let vendorList = getValueFromLocalStroage("allVendorData");
    if (!vendorList.includes(key)) {
      vendorList.push(key);
      updateStoreInLocalStroage("allVendorData", vendorList);
    }
    if (data && data.length > 0 && isChecked) {
      setIsChecked(false);
      setIsError(false);
      updateStoreInLocalStroage(key, []);
      const indexToRemove = vendorList.indexOf(key);
      if (indexToRemove !== -1) {
        vendorList.splice(indexToRemove, 1);
      }
      updateStoreInLocalStroage("allVendorData", vendorList);
    } else if (
      data &&
      data.length > 0 &&
      isChecked === false &&
      value === true
    ) {
      setIsChecked(false);
      setIsError(true);
      toast.error("Uncheck this card and select one Card per website !!!");
    } else if (
      data &&
      data.length > 0 &&
      isChecked === false &&
      value === false
    ) {
      setIsChecked(false);
      setIsError(false);
    } else {
      setIsChecked(true);
      setIsError(false);
      updateStoreInLocalStroage(key, teamsLabelwithPrice);
    }
  };

  // Tick is handlig all the state and logic from the valtio
  const tickCheckedButton = async (value, event) => {
    checkTickConditions(vendor, value);
  };

  useEffect(() => {
    if (scrapperName === "MojoBreakerScrapper") {
      setWebsiteName("MojoBreak");
    } else if (scrapperName === "BlezBreakerScrapper") {
      setWebsiteName("TheBlezBreak");
    } else if (scrapperName === "JaspysCaseScrapper") {
      setWebsiteName("JASPYS");
    } else if (scrapperName === "LaytonSportCardScrapper") {
      setWebsiteName("Layton Sports Cards");
    } else if (scrapperName === "BomberBreaksScrapper") {
      setWebsiteName("Bomber Breaks");
    } else if (scrapperName === "FanaticsLiveScrapper") {
      setWebsiteName("Fanatics Live");
    }
  }, []);

  return (
    <div
      style={{
        // position: "relative",
        borderRadius: "12px",
        border: `${isError ? "1px solid #FF0000 " : "1px solid #E7E4E4"}`,
        background: "#EFEFEF",
        boxShadow: "2px 3px 15px 1px #0000001F, -2px -2px 15px 1px #00000014",
      }}
      className="overflow-hidden px-6 pt-4 pb-6 rounded-xl shadow-2xl "
    >
      <div className="">
        <div className="flex items-center text-center justify-between mb-3">
          <h1 className="text-black font-semibold text-center text-xl flex-grow">{`Website:  ${websiteName}`}</h1>
          <Checkbox
            icon={
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  backgroundColor: "#22C55E",
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon.FiCheck color="white" size={24} />
              </div>
            }
            name="my-input"
            checked={isChecked}
            onChange={tickCheckedButton}
            borderColor="#22C55E"
            style={{ cursor: "pointer", height: 28, width: 28 }}
            labelStyle={{ marginLeft: 5, userSelect: "none" }}
            label=""
          />
        </div>

        <h2 style={{ color: "#615F5F" }} className="text-sm font-normal mb-4">
          {cardTitle}
        </h2>
        <p className="text-black font-medium leading-5 mb-2">
          {`Sport: ${sport}`}
        </p>
        <p className="text-black font-normal text-sm leading-5 mb-2">
          {`Vendor: ${vendor}`}
        </p>
        <div className="mb-4">
          <p
            style={{ color: "#615F5F" }}
            className="font-medium leading-5 mb-6"
          >
            {cardRegularPrice}
          </p>
          <p className="text-black font-medium leading-5 mb-2">
            Label Price with Teams:
          </p>
          <div className="">
            <select
              style={{ color: "#646464" }}
              className=" w-full bg-white border rounded-md shadow-lg p-2"
              onChange={(e) => handleTeamSelect(e.target.value)}
              value={selectedTeam}
            >
              <option value="">Select Team</option>
              {teamsLabelwithPrice.map((team) => (
                <option key={team.label} value={team.label}>
                  {team.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedTeam && (
          <div>
            <p className="text-sm text-black font-normal leading-5 mb-0">
              Team : {selectedTeam}
            </p>
            <p
              style={{ color: "#615F5F" }}
              className="text-sm font-normal mb-4"
            >
              Price:{" "}
              {
                teamsLabelwithPrice.find((team) => team.label === selectedTeam)
                  .price
              }
            </p>
          </div>
        )}
      </div>
      <p className="font-normal text-black leading-5 mb-2">
        {" "}
        Scrapper: {scrapperName}
      </p>
      <div className="flex flex-grow gap-2 mb-16">
        <p style={{ color: "#5D91FE" }} className="text-normal leading-5">
          {" "}
          Copy card link:
        </p>
        <button className="" onClick={copyToClipboard}>
          {isCopied ? "Copied!" : <CopyText />}
        </button>
      </div>
      <div className="items-center text-center justify-center px-16 pr-16 h-10">
        <DownloadExcelSheet
          teamsLabelwithPrice={teamsLabelwithPrice}
          cardTitle={cardTitle}
        />
      </div>
    </div>
  );
};

export default ScraperCard;
