export const urls = {
  // baseURL: "http://localhost:8800/api/", // base URL for API requests
  // baseImageURL: "http://localhost:8800/",          // base url for image
  baseURL: "https://api.breakcomp.com/api/", // base URL for API requests
  baseImageURL: "https://api.breakcomp.com/", // base url for image
  // baseImageURL: "http://localhost:8800/", // base url for image

  // ---------------------------
  login: `admin/login`, // login admin user

  adminlogout: `admin/logout`, // admin user loguout

  // ---------------------------
  addSportCard: `admin/sport-card`, // post a new sport card
  addBulkSportCard: `admin/sport-bulk-card`,
  addBulkSportsCardsLink: `admin/sport-bulk-upload-via-excelsheet-links`,
  getSportCards: `admin/sport-cards`, // get all sport cards
  getSportCard: `admin/sport-card/`, // get single sport card
  getAllSportCards: `admin/sport-cards-pagination/`,
  duplicateSportCard: `admin/sport-card/`, // get single sport card
  updateSportCard: `admin/sport-card/update/`, // get single sport card
  deleteSportCard: `admin/sport-card/delete/`, // delete a specific card
  // ---------------------------

  // ---------------------------
  addNewRelease: `admin/new-release`, // post a new release
  getRelease: `admin/new-releases/`, // post a new release
  duplicateNewRelease: `admin/new-release/`, // get single sport card
  deleteNewRelease: `admin/new-release/delete/`, // delete a specific card
  updateNewRelease: `admin/new-release/update/`, // get single sport card
  // ---------------------------
};
