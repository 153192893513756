import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Header from "../../components/Header";
import UploadCards from "./UploadCards";
import UploadCardsViaExcelButton from "./UploadCardsViaExcelButton";

const UploadBulkCardsToWebsiteViaExcelSheet = () => {
  const [excelData, setExcelData] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setExcelData(jsonData);
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <>
      <Header />
      <div className="flex flex-col items-start px-36 pt-10 pr-36 gap-10">
        <h1 className="text-black font-semibold leading-6 text-2xl">
          Upload teams data excelsheet, which holds prices with associated teams
          and vendors.
        </h1>
        <div className="flex justify-center w-full">
          <div className=" flex flex-wrap rounded-lg gap-8 text-gray-600 leading-4 items-center">
            <label className="text-lg  text-black font-medium ">
              Upload Teams Data ExcelSheet:
            </label>
            <input
              type="file"
              onChange={handleFileUpload}
              className="rounded-md border border-solid- text-sm file:mr-4 file:py-2 file:px-6 file:rounded-md file:border-0 file:font-medium file:bg-violet-100 file:text-gray-600"
            />

            {excelData?.length > 0 && (
              <div className="flex justify-end mx-36">
                <div className=" flex flex-wrap rounded-lg gap-8 text-gray-600 leading-4 items-center">
                  <UploadCardsViaExcelButton teams={excelData} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadBulkCardsToWebsiteViaExcelSheet;
