import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { newRequestToScrappingDB } from "../../utils/newRequest";
import { ScrapperButtonWithLoader } from "./ScraperButton";
import Sports from "./Sports";
import { toast } from "react-toastify";

const HomeScrapper = () => {
  const [sports, getAllSports] = useState([]);
  const [isMojoLoading, setIsMojoLoading] = useState({
    loading: false,
    dataFetched: null,
  });
  const [isBlezLoading, setIsBlezLoading] = useState({
    loading: false,
    dataFetched: null,
  });
  const [isJaSpysCaseLoading, setJaSpysCaseLoading] = useState({
    loading: false,
    dataFetched: null,
  });

  const [isLaytonsBreakLoading, setLaytonsBreakLoading] = useState({
    loading: false,
    dataFetched: null,
  });

  const [isBomberBreakLoading, setBomberBreakLoading] = useState({
    loading: false,
    dataFetched: null,
  });
  const [isFanticsLoading, setFanticsLoading] = useState({
    loading: false,
    dataFetched: null,
  });

  const [isWhatnotLoading, setWhatnotLoading] = useState({
    loading: false,
    dataFetched: null,
  });

  const [isFacebookLoading, setIsFacebookLoading] = useState({
    loading: false,
    dataFetched: null,
  });

  const MojoBreakRunner = async (sports) => {
    // alert("Mojo Script is about to run");
    if (sports.length === 0) {
      return toast.error("Please select sport first");
    }
    setIsMojoLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("mojobreak/run", {
      sports,
    });
    setIsMojoLoading({ loading: false, dataFetched: true });
  };

  const TheBlezBreakRunner = async (sports) => {
    // alert("Mojo Script is about to run");
    if (sports.length === 0) {
      return toast.error("Please select sport first");
    }
    setIsBlezLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("theblez/run", {
      sports,
    });

    setIsBlezLoading({ loading: false, dataFetched: true });
    // console.log("Result is here ::::::::::::", result);
  };

  const JaspyscaseBreakRunner = async (sports) => {
    // alert("Mojo Script is about to run");
    if (sports.length === 0) {
      return toast.error("Please select sport first");
    }
    setJaSpysCaseLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("jaspyscase/run", {
      sports,
    });
    setJaSpysCaseLoading({ loading: false, dataFetched: true });
    // console.log("Result is here ::::::::::::", result);
  };

  const BomberBreakRunner = async (sports) => {
    // alert("Mojo Script is about to run");
    if (sports.length === 0) {
      return toast.error("Please select sport first");
    }
    setBomberBreakLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("bomberbreak/run", {
      sports,
    });
    setBomberBreakLoading({ loading: false, dataFetched: true });
    // console.log("Result is here ::::::::::::", result);
  };

  const LaytonSportsCardsBreakRunner = async (sports) => {
    // alert("Mojo Script is about to run");
    if (sports.length === 0) {
      console.log("Empty");
      return toast.error("Please select sport first");
    }
    setLaytonsBreakLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("laytonsportscards/run", {
      sports,
    });
    setLaytonsBreakLoading({ loading: false, dataFetched: true });
    console.log("Result is here ::::::::::::", result);
  };

  //  Fanatic Live Integration
  const fanaticsLiveScrapperRunner = async () => {
    // alert("Mojo Script is about to run");
    setFanticsLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("fanaticslive/run");
    if (result?.data?.scrappedResult?.success) {
      setFanticsLoading({ loading: false, dataFetched: true });
    } else {
      setFanticsLoading({ loading: false, dataFetched: false });
    }
  };

  //  Whatnot Integration
  const whatnotScrapperRunner = async () => {
    // alert("Mojo Script is about to run");
    setWhatnotLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("whatnot/run");
    if (result?.data) {
      setWhatnotLoading({ loading: false, dataFetched: true });
    } else {
      setWhatnotLoading({ loading: false, dataFetched: false });
    }
  };

  const facebookScrapperRunner = async () => {
    // alert("Mojo Script is about to run");
    setIsFacebookLoading({ loading: true, dataFetched: null });
    const result = await newRequestToScrappingDB.post("whatnot/run");
    if (result?.data) {
      setIsFacebookLoading({ loading: false, dataFetched: true });
    } else {
      setIsFacebookLoading({ loading: false, dataFetched: false });
    }
  };

  useEffect(() => {
    // const callme = async () => {
    //   const result = await newRequestToScrappingDB.get("sport/sports");
    //   console.log(result);
    //   setSports(result.data.data);
    // };
    // callme();
  }, [sports]);

  return (
    <>
      <Header />
      <Sports getAllSports={getAllSports} />
      <div className="flex flex-col items-start justify-start mt-16 mb-16">
        <h1 className="text-black text-3xl font-extrabold mx-36 ">
          Run Scrapper{" "}
        </h1>
        <div className="mt-16 mx-36 w-4/5">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-11">
            <ScrapperButtonWithLoader
              color={"#16a34a"}
              onClick={MojoBreakRunner}
              label="Mojobreak Scrapper"
              isLoading={isMojoLoading}
              statusMessage={"Mojo Scrapper is running please wait!!!"}
              sports={sports}
            />
            <ScrapperButtonWithLoader
              color={"#ef4444"}
              onClick={TheBlezBreakRunner}
              label="The Blez Break Scrapper"
              isLoading={isBlezLoading}
              statusMessage="BlezBreak Scrapper is running please wait!!!"
              sports={sports}
            />
            <ScrapperButtonWithLoader
              color={"#0e7490"}
              onClick={JaspyscaseBreakRunner}
              label="Jaspyscase Scrapper"
              isLoading={isJaSpysCaseLoading}
              statusMessage="Jaspyscase Scrapper is running please wait!!!"
              sports={sports}
            />

            <ScrapperButtonWithLoader
              color={"#d97706"}
              onClick={LaytonSportsCardsBreakRunner}
              label="LaytonSportsCard Scrapper"
              isLoading={isLaytonsBreakLoading}
              statusMessage="LaytonSportsCard Scrapper is running please wait!!!"
              sports={sports}
            />

            <ScrapperButtonWithLoader
              color={"#a21caf"}
              onClick={BomberBreakRunner}
              label="BomberBreak Scrapper"
              isLoading={isBomberBreakLoading}
              statusMessage="BomberBreak Scrapper is running please wait!!!"
              sports={sports}
            />

            <ScrapperButtonWithLoader
              color={"#164e63"}
              onClick={fanaticsLiveScrapperRunner}
              label="Fanatics Live Scrapper"
              isLoading={isFanticsLoading}
              statusMessage="Fanatics Live Scrapper is running please wait!!!"
              sports={sports}
            />

            <ScrapperButtonWithLoader
              color={"#164e63"}
              onClick={whatnotScrapperRunner}
              label="Whatnot Scrapper"
              isLoading={isWhatnotLoading}
              statusMessage="Whatnot Scrapper is running please wait!!!"
              sports={sports}
            />

            {/* <ScrapperButtonWithLoader
              color={"#164e63"}
              onClick={facebookScrapperRunner}
              label="Facebook Scrapper"
              isLoading={isFacebookLoading}
              statusMessage="Facebook Scrapper is running please wait!!!"
              sports={sports}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScrapper;
