import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import newRequest from "./../utils/newRequest";
import { Link } from "react-router-dom";
import { BsEyeSlashFill } from "react-icons/bs";

import logo from "../assets/Price Comparison Logo.svg";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await newRequest.post("/admin/login", { username, password });
      localStorage.setItem("currentUser", JSON.stringify(res.data));
      navigate("/sport-card-list");
      setLoading(false);
    } catch (err) {
      setError(err.response.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    // check if there is a currentUser in localStorage
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser !== null) {
      // If currentUser exists in localStorage, navigate to home screen
      navigate("/sport-card-list");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex justify-between pl-20 ">
          <div className="mx-10 mt-5">
            {/* <h1 className="text-[#0033FF] font-bold text-xl">
              Price Comparison
            </h1> */}
            <div>
              <img src={logo} className="h-full w-[220px]" alt="Price Comparison Logo" />
            </div>
            <div className="mt-4">
              <h1 className="text-3xl font-bold">Admin Log in</h1>
              <p className="text-[16px/25px] mt-4">
                Enter your credentials to proceed
              </p>
            </div>
            <div>
              <form className="mt-12" onSubmit={handleSubmit}>
                <div className="flex flex-col items-center gap-y-6">
                  <input
                    className="border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="text"
                    value={username}
                    onChange={handleUserNameChange}
                    placeholder="User Name*"
                    required
                  />

                  <div className="flex items-center border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4">
                    <input
                      className="flex-1 outline-none"
                      name="password"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password*"
                      required
                    />
                    <BsEyeSlashFill className="text-gray-400" />
                  </div>
                  <div className="flex justify-between w-[100%]">
                    <label className="text-[#B6B6B8] font-medium flex items-center gap-4">
                      <input
                        type="checkbox"
                        name="remember"
                        className="w-4 h-4"
                      />
                      <p>Remember Me</p>
                    </label>
                    <Link
                      to="/forget-password"
                      className="text-[#0033FF] font-medium"
                    >
                      Forget Password?
                    </Link>
                  </div>
                  <input
                    className="border-[2px] font-bold border-[#0033FF] bg-[#0033FF]
                  text-white py-3 w-[330px] h-[50px] rounded-[4px] hover:bg-transparent
                  hover:text-[#0033FF]"
                    type="submit"
                    value="Login"
                  />
                </div>
              </form>
              <div className="text-center mt-4">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-[#0033FF] font-medium">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="relative">
            <img src="/Assets/Shape.svg" className="h-[100vh] object-cover " />
            <img
              src="/Assets/Login Illustration.svg"
              className="absolute top-[20%] right-[10%]"
            />
          </div>
        </div>
      )}
    </>
  );
}
