import React, { useState } from "react";

const RoundedPillLabel = ({ label, getSelectedPillText }) => {
  const [selected, setSelected] = useState(false);

  return (
    <div
      style={{
        background: selected ? "#03BEEB" : "#EFEDED",
        border: "1px solid #D0D9EB",
      }}
      className={`rounded-xl px-4 py-2 cursor-pointer`}
      onClick={() => {
        getSelectedPillText((prev) => {
          if (!selected) {
            return [...prev, label];
          } else {
            return [...prev]
              .map((item) => {
                if (item !== label) {
                  return item;
                }
              })
              .filter(Boolean);
          }
        });
        setSelected(!selected);
      }}
    >
      {label}
    </div>
  );
};

export default RoundedPillLabel;
