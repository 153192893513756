import React, { useState } from "react";
import CrossButtonSvg from "./CrossButtonSvg";
import RichTextEditor from "../../components/RichTextEditor";
import Loader from "../../components/Loader";
import { LoaderSpinner } from "./CardLoaderSpinner";

// Modal component
const CardInfoModal = ({ isOpen, onClose, onSubmit }) => {
  const [isFormDisabled, setFormDisable] = useState(false);
  const [formData, setFormData] = useState({
    // Initialize state for each input field
    name: "",
    email: "",
    image: null,
    sport: "",
    year: "",
    brand: "",
    team: "",
    boxOrCaseBreak: "",
    pytOrRandomBreak: "PYT",
    breaker: "",
    price: "",
    description: "",
    prices: [
      {
        vendor: "",
        product: "",
        price: "",
        seller: "",
        date: "",
        sellerLink: "",
      },
    ],
    // Add more fields as needed
  });

  const handleDescriptionChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  // Function to handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // If it's a file input, store the file object
    if (name === "image") {
      setFormData({
        ...formData,
        [name]: files[0], // Only consider the first file if multiple files are allowed
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisable(true);
    // setTimeout(async () => {
    try {
      // Pass the form data to the parent component
      // console.log(formData.image);
      await onSubmit(formData);
      // Close the modal
      setFormData({
        // Initialize state for each input field
        name: "",
        email: "",
        image: null,
        sport: "",
        year: "",
        brand: "",
        team: "",
        boxOrCaseBreak: "",
        pytOrRandomBreak: "PYT",
        breaker: "",
        price: "",
        description: "",
        prices: [
          {
            vendor: "",
            product: "",
            price: "",
            seller: "",
            date: "",
            sellerLink: "",
          },
        ],
        // Add more fields as needed
      });
    } finally {
      setFormDisable(false);
      onClose();
    }
    // }, 5000);
  };

  const emptyObjectAndClose = () => {
    setFormData({
      // Initialize state for each input field
      name: "",
      email: "",
      image: null,
      sport: "",
      year: "",
      brand: "",
      team: "",
      boxOrCaseBreak: "",
      pytOrRandomBreak: "PYT",
      breaker: "",
      price: "",
      description: "",
      prices: [
        {
          vendor: "",
          product: "",
          price: "",
          seller: "",
          date: "",
          sellerLink: "",
        },
      ],
      // Add more fields as needed
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className=" absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div
        className="bg-white rounded-xl shadow-md "
        style={{ width: "600px" }}
      >
        <div className="relative p-8">
          <div className="flex flex-row-reverse">
            <h1
              className="cursor-pointer font-black text-black"
              onClick={emptyObjectAndClose}
            >
              <CrossButtonSvg />
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            {/* Render input fields for all data */}
            <label htmlFor="image" className="block mb-2">
              Upload Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />
            <label htmlFor="sport" className="block mb-2">
              Sport:
            </label>
            <input
              type="text"
              id="sport"
              name="sport"
              value={formData.sport}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />
            <label htmlFor="year" className="block mb-2">
              Year:
            </label>
            <input
              type="text"
              id="year"
              name="year"
              value={formData.year}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />
            <label htmlFor="brand" className="block mb-2">
              Brand:
            </label>
            <input
              type="text"
              id="brand"
              name="brand"
              value={formData.brand}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />
            <label htmlFor="boxOrCaseBreak" className="block mb-2">
              Box or Case Break:
            </label>
            <input
              type="text"
              id="boxOrCaseBreak"
              name="boxOrCaseBreak"
              value={formData.boxOrCaseBreak}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />

            <label htmlFor="pytOrRandomBreak" className="block mb-2">
              PYT or Random Break:
            </label>
            <input
              type="text"
              id="pytOrRandomBreak"
              name="pytOrRandomBreak"
              value={formData.pytOrRandomBreak}
              onChange={handleInputChange}
              className="w-full px-4 py-2 mb-4 border rounded-md"
            />

            <label htmlFor="description" className="block mb-2">
              Description:
            </label>
            {/* <input
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mb-4 border rounded-md"
          /> */}
            <RichTextEditor
              onChange={handleDescriptionChange}
              value={formData.description}
            />
            {/* Add more input fields for brand, team, boxOrCaseBreak, etc. */}
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md mt-2"
            >
              Submit
            </button>
          </form>
          {/* Loader */}
          {isFormDisabled && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "12px",
              }}
            >
              <LoaderSpinner />
              <div className="text-white text-sm font-semibold mt-2">
                please wait ...
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardInfoModal;
