import React from "react";

const CopyText = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_366)">
        <path
          d="M9.52734 18.5H2.8125C1.26165 18.5 0 17.2383 0 15.6875V6.16016C0 4.60931 1.26165 3.34766 2.8125 3.34766H9.52734C11.0782 3.34766 12.3398 4.60931 12.3398 6.16016V15.6875C12.3398 17.2383 11.0782 18.5 9.52734 18.5ZM2.8125 4.75391C2.03713 4.75391 1.40625 5.38479 1.40625 6.16016V15.6875C1.40625 16.4629 2.03713 17.0938 2.8125 17.0938H9.52734C10.3027 17.0938 10.9336 16.4629 10.9336 15.6875V6.16016C10.9336 5.38479 10.3027 4.75391 9.52734 4.75391H2.8125ZM15.1523 13.9297V3.3125C15.1523 1.76165 13.8907 0.5 12.3398 0.5H4.53516C4.14679 0.5 3.83203 0.814754 3.83203 1.20312C3.83203 1.5915 4.14679 1.90625 4.53516 1.90625H12.3398C13.1152 1.90625 13.7461 2.53713 13.7461 3.3125V13.9297C13.7461 14.3181 14.0608 14.6328 14.4492 14.6328C14.8376 14.6328 15.1523 14.3181 15.1523 13.9297Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_366">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyText;
