import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import AddSportCard from "./pages/AddSportCard";
import SportCardList from "./pages/SportCardList";
import Header from "./components/Header";
import SportCard from "./pages/SportCard";
import EditSportCard from "./pages/EditSportCard";
import AddDropdownOptions from "./pages/AddDropdownOptions";
import NewReleaseClander from "./pages/NewReleaseCalendar";
import NewsList from "./pages/NewsList";
import AddCalendar from "./pages/AddCalendar";
import EditCalendar from "./pages/EditCalendar";
import NewReleaseCalendar from "./pages/NewReleaseCalendar";
import RegisteredUser from "./pages/RegisteredUser";
import AddNewList from "./pages/AddNewList";
import { useEffect, useState } from "react";
import HomeScrapper from "./Scrapper/components/HomeScrapper";
import UploadExelSheet from "./Scrapper/components/uploadExelSheet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sports from "./Scrapper/components/Sports";
import UploadCardsToWebsite from "./Scrapper/components/uploadCardsTOWebsite";
import Home from "./Ad/Components/Home";
import UploadBulkCardsToWebsiteViaExcelSheet from "./Scrapper/components/UploadBulkCardsViaExcelsheet";

function checkAuthToken() {
  const currentUser = localStorage.getItem("currentUser");
  // Check if currentUser exists in localStorage
  if (currentUser) {
    // You can perform additional checks on the currentUser object if needed
    // For example, checking if the token is valid or expired
    return true; // Return true if user is logged in
  }
  return false; // Return false if user is logged out
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  useEffect(() => {
    const isLoggedIn = checkAuthToken(); // Function to check if user is logged in, returns true or false
    setIsLoggedIn(isLoggedIn);
  }, []);

  const RestrictedRoute = ({ path, element }) => {
    if (!isLoggedIn && path !== "/login") {
      return <Navigate to="/login" />;
    }
    return element;
  };
  return (
    <>
      <div className="App">
        <Router>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/add-sport-card" element={<AddSportCard />} />
            <Route path="/sport-card-list" element={<SportCardList />} />
            <Route path="/sport-card/:id" element={<SportCard />} />
            <Route path="/sport-card/edit/:id" element={<EditSportCard />} />
            <Route path="/add-new-options" element={<AddDropdownOptions />} />
            <Route path="/calendar" element={<NewReleaseCalendar />} />
            <Route path="/contact-users" element={<NewsList />} />
            <Route path="/registered-users" element={<RegisteredUser />} />
            <Route path="/add-calendar" element={<AddCalendar />} />
            <Route path="/add-calendar/edit/:id" element={<EditCalendar />} />
            <Route path="/add-new-list" element={<AddNewList />} />
            <Route path="/after-clicking-button" element={<HomeScrapper />} />
            <Route
              path="/after-clicking-upload-excelsheet"
              element={<UploadExelSheet />}
            />
            <Route
              path="/website-cards-upload-excelsheet"
              element={<UploadCardsToWebsite />}
            />
            <Route path="/manage-ad" element={<Home />} />

            <Route
              path="/bulk-pload-via-excelsheet"
              element={<UploadBulkCardsToWebsiteViaExcelSheet />}
            />

            {currentUser && (
              <Route
                path="/*"
                element={<Navigate replace to="/sport-card-list" />}
              />
            )}
          </Routes>
        </Router>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
