import React, { useEffect, useState } from "react";
import newRequest from "./../utils/newRequest";
import { urls } from "./../utils/urls";
import { useParams } from "react-router-dom";
import "../styles/sport-card.css";
import Loader from "./../components/Loader";
import Header from "../components/Header";
export default function SportCard() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await newRequest.get(`${urls.getSportCard}${id}`);
        // console.log(JSON.stringify(response.data));
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchCards();
  }, []);
  // console.log("data ...", data);

  return (
    <>
      <Header />
      <div className="flex justify-center items-center container-box mx-auto   rounded-l-md h-[80vh]">
        {loading ? (
          <Loader />
        ) : (
          <section className=" shadow-lg flex">
            <div className=" rounded-l-md mr-4">
              <img
                className="w-[100%] rounded-l-md object-cover h-full"
                src={`${urls.baseImageURL}uploads/${data?.image}`}
                alt={data?.team}
              />
            </div>
            <div className="flex flex-col gap-4 border-r-[3px] w-[100%] border-[#00D62B] py-4">
              <h3 className="font-bold w-[70%] text-[#0033FF]">
                {data?.year} {data?.brand} {data?.sport} {data?.team} Trading
                Card {data?.boxOrCaseBreak}
              </h3>
              <p
                className="overflow-y-auto"
                style={{ whiteSpace: "pre-wrap", maxHeight: "20vh" }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              >
                {/* {data?.description} */}
              </p>

              <div className="flex gap-8 border-t-[3px] border-[#00D62B] pt-4 w-[100%]">
                {data?.prices.slice(0, 3).map((vendor, index) => (
                  <div className="flex flex-col gap-2" key={index}>
                    <label>{vendor.vendor}</label>
                    <span>${vendor.price}</span>
                  </div>
                ))}
                {data?.prices?.length > 3 && (
                  <div className="relative">
                    <div className="flex flex-col gap-2 rounded shadow hover:shadow-md transition duration-300 ease-in-out">
                      <select className="outline-none appearance-none bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:ring focus:border-blue-500">
                        <option value="" disabled>
                          {data?.prices?.length - 3} more
                        </option>
                        {data?.prices?.slice(3).map((vendor, index) => (
                          <option
                            value={`${vendor.price} ${vendor.vendor}`}
                            key={index}
                          >
                            {`${vendor.price}$ ${vendor.vendor}`}
                          </option>
                        ))}
                      </select>
                      <span className="pl-[5px]">
                        {data?.prices[4]?.price}$
                      </span>
                      {/* <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg
                      className="fill-current h-4 w-4 text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 7.293a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L8 9.414V15a1 1 0 01-2 0V9.414L4.293 10.707a1 1 0 01-1.414-1.414l3-3zM15 8a1 1 0 011 1v5a1 1 0 11-2 0V9a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-6 justify-center items-center w-[200px]">
              <h1 className="font-bold text-4xl">${data?.price}</h1>

              <button className="bg-[#0033FF] text-white rounded-sm px-6 py-2">
                View
              </button>
            </div>
          </section>
        )}
      </div>
    </>
  );
}
