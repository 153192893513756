import React from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import {
  clearStoreInLocalStroage,
  getValueFromLocalStroage,
} from "../../GlobalStateManagement";
const ExportCombineExcelSheet = ({ excelSheetName }) => {
  // Helper Function
  function getPrice(array, team) {
    const matchingTeam = array.find(
      (t) => t.label.toLowerCase().trim() === team.toLowerCase()
    );
    return matchingTeam ? matchingTeam.price.replace("$", "") : 0;
  }

  const downLoadExcelSheet = () => {
    if (excelSheetName.length === 0) {
      toast.error("Enter Excelsheet name first");
      return;
    }
    let vendorList = getValueFromLocalStroage("allVendorData");
    let vendorListWithAssociatedTeams = [];
    let vendorSequence = [];
    for (let i = 0; i < vendorList.length; i++) {
      let data = getValueFromLocalStroage(vendorList[i]);
      vendorListWithAssociatedTeams.push(data);
      vendorSequence.push(vendorList[i]);
    }
    if (vendorList.length > 0) {
      // Your code here if the length of at least one data is greater than 0
      const allTeams = [
        ...new Set(
          [...vendorListWithAssociatedTeams.flat()].map((team) =>
            team.label.toLowerCase().trim()
          )
        ),
      ];
      // Create an array of headers
      const headers = ["Team Name", ...vendorSequence];

      // Create an array for the teams data
      const teamsData = [
        headers,
        ...allTeams.map((team) => {
          let vendorSequenceList = [];
          for (let i = 0; i < vendorListWithAssociatedTeams.length; i++) {
            vendorSequenceList.push(
              getPrice(vendorListWithAssociatedTeams[i], team)
            );
          }
          return [team, ...vendorSequenceList];
        }),
      ];

      // Create a workbook and a worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(teamsData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "TeamsData");

      // Save the workbook to a file
      XLSX.writeFile(workbook, `${excelSheetName}.csv`, { bookSST: true });

      clearStoreInLocalStroage();

      // console.log("Excel file created successfully!");
    } else {
      // Your code here if all lengths are 0
      toast.error("Select any of the card!!!");
    }
  };

  return (
    <button
      className="bg-green-500 text-black font-semibold h-10 px-4 py-2 rounded cursor-pointer"
      onClick={downLoadExcelSheet}
    >
      Export Combine Excelsheet
    </button>
  );
};

export default ExportCombineExcelSheet;
