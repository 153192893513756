import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import newRequest from "./../utils/newRequest";
import { urls } from "./../utils/urls";
import Loader from "./../components/Loader";
import { FaEdit } from "react-icons/fa";
import { GrView, GrDuplicate } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CgArrowAlignV } from "react-icons/cg";
import Header from "../components/Header";
import HomeScrapperNavigator from "../Scrapper/components/ButtonScrapper";
import ReactPaginate from "react-paginate";

export default function SportCardList() {
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const fetchCards = async () => {
    try {
      setLoading(true);
      const response = await newRequest.get(
        `${urls.getAllSportCards}?page=${
          pageNumber + 1
        }&pageSize=${itemsPerPage}&searchTerm=${searchTerm}`
      );
      setData(response.data.sportCards);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCards();
  }, [pageNumber, searchTerm, itemsPerPage]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const deleteSportCard = async (id) => {
    try {
      setLoading(true);
      setData((prev) => {
        return prev.filter((item) => item._id !== id);
      });
      const response = await newRequest.delete(`${urls.deleteSportCard}${id}`);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw error;
    }
  };

  const handleDuplicate = async (id) => {
    try {
      const response = await newRequest.post(
        `${urls.duplicateSportCard}${id}/duplicate`
      );
      const duplicatedSportCard = response.data;
      setData((prev) => [...prev, duplicatedSportCard]);
      alert("Sport card duplicated successfully!");
      navigate("/sport-card-list");
    } catch (error) {
      console.error(error);
      alert("Error duplicating sport card.");
    }
  };
  useEffect(() => {
    setSearchTerm(""); // Clear search input when pageNumber changes
  }, [pageNumber]);
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    setFilteredData(
      data?.filter(
        (item) =>
          item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.team.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);

  return (
    <>
      <Header />
      <HomeScrapperNavigator />
      <div className="w-full flex gap-8">
        <div className="w-[15%]">
          <nav
            id="sidenav-1"
            className="absolute mt-[10.25rem] left-0 top-0 z-[1035] h-full w-60 -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
            data-te-sidenav-init
            data-te-sidenav-hidden="false"
            data-te-sidenav-position="absolute"
          >
            <ul className="relative m-0 list-none">
              <li className="relative pb-4">
                <Link
                  className="flex h-14 items-center font-bold px-6 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear bg-[#423D68] text-white "
                  data-te-sidenav-link-ref
                >
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="relative">
                <ul>
                  <li className="relative">
                    <Link
                      to="/calendar"
                      className="flex h-12 font-bold  cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-4 text-[0.90rem] outline-none transition ease-linear hover:bg-slate-50  hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Add New Releases
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/contact-users"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Contact Userlist
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/registered-users"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Registered Users
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/add-new-list"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Add New List
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div className=" w-[80%] overflow-hidden my-14">
          <div className="flex justify-between flex-wrap items-center">
            <button
              className="mr-[5px] px-2 py-2 bg-[#B72E9B] text-white"
              onClick={() => navigate("/add-sport-card")}
            >
              Add New Card +
            </button>
            {/* search bar  */}
            <div>
              <input
                type="text"
                className="border rounded p-2 w-[20rem]"
                placeholder="Search Band & Team"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <table className="w-full border-collapse mt-3 mb-10 shadow-md rounded-md">
            <thead className="bg-[#423D68] text-white font-bold">
              <tr>
                <th className="text-left px-6 py-4 w-[5%]">#</th>
                <th className="text-left px-6 py-4 w-[10%]">Card</th>
                <th
                  className={`text-left px-6 cursor-pointer  w-[10%] py-4 ${
                    sortColumn === "sport" ? "underline cursor-pointer" : ""
                  }`}
                  onClick={() => handleSort("sport")}
                >
                  Sport
                  {sortColumn === "sport" && (
                    <CgArrowAlignV
                      className={`inline-block ml-1 ${
                        sortOrder === "asc" ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                </th>
                <th
                  className={`text-left cursor-pointer px-6 w-[10%] py-4 ${
                    sortColumn === "year" ? "underline cursor-pointer" : ""
                  }`}
                  onClick={() => handleSort("year")}
                >
                  Year
                  {sortColumn === "year" && (
                    <CgArrowAlignV
                      className={`inline-block ml-1 ${
                        sortOrder === "asc" ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                </th>
                <th
                  className={`text-left cursor-pointer w-[30%] px-6 py-4 ${
                    sortColumn === "brand" ? "underline cursor-pointer" : ""
                  }`}
                  onClick={() => handleSort("brand")}
                >
                  Brand
                  {sortColumn === "brand" && (
                    <CgArrowAlignV
                      className={`inline-block ml-1 ${
                        sortOrder === "asc" ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                </th>
                <th
                  className={`text-left cursor-pointer w-[30%] px-6 py-4 ${
                    sortColumn === "team" ? "underline cursor-pointer" : ""
                  }`}
                  onClick={() => handleSort("team")}
                >
                  Team
                  {sortColumn === "team" && (
                    <CgArrowAlignV
                      className={`inline-block ml-1 ${
                        sortOrder === "asc" ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                </th>
                <th className="text-left px-6 py-4">Actions</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <Loader />
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={item._id}>
                    <td className="text-left px-6 py-4 font-bold">
                      {index + 1 + pageNumber * itemsPerPage}
                    </td>
                    <td className="text-left px-6 py-4 font-bold">
                      <img
                        src={`${urls.baseImageURL}uploads/${item.image}`}
                        alt=""
                        className="h-[40px] w-[40px] rounded-md"
                      />
                    </td>
                    <td className="text-left px-6 py-4 font-bold">
                      {item.sport}
                    </td>
                    <td className="text-left px-6 py-4 font-bold">
                      {item.year}
                    </td>
                    <td className="text-left px-6 py-4 font-bold">
                      {item.brand}
                    </td>
                    <td className="text-left px-6 py-4 font-bold">
                      {item.team}
                    </td>
                    <td className="text-left pl-6 py-6 font-bold flex gap-4">
                      <Link
                        to={`/sport-card/edit/${item._id}`}
                        className="link"
                      >
                        <FaEdit size={25} className="text-[blue]" />
                      </Link>
                      <Link to={`/sport-card/${item._id}`} className="link">
                        <GrView className="text-[gray]" size={25} />
                      </Link>
                      <RiDeleteBin5Line
                        className="text-[red] cursor-pointer"
                        size={25}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this sport card?"
                            )
                          ) {
                            deleteSportCard(item._id);
                          }
                        }}
                      />
                      <button
                        className="text-blue cursor-pointer"
                        onClick={() => handleDuplicate(item._id)}
                      >
                        <GrDuplicate />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div class="flex items-center justify-end ">
            {/* <div class="bg-gray-200 p-1 rounded-lg shadow-lg mt-2">
              <input
                type="number"
                id="items-per-page"
                placeholder="10 Items"
                class="w-32 px-2 py-1 mr-2 rounded leading-3 bg-gray-100 focus:outline-none focus:bg-white"
                onChange={(event) =>
                  setItemsPerPages(parseInt(event.target.value))
                }
              />
              <button
                onClick={() => {
                  setItemsPerPage(itemsPerPages);
                  setPageNumber(0);
                }}
                class="px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Show
              </button>
            </div> */}
            <div className="mb-3">
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
