import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import newRequest from "./../utils/newRequest";
import { ReactComponent as Logo } from "../assets/Price Comparison Logo.svg";
import { clearStoreInLocalStroage } from "../GlobalStateManagement";

export default function Header() {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  // console.log(currentUser);
  const handleLogout = async () => {
    try {
      await newRequest.post("/admin/logout");
      localStorage.setItem("currentUser", null);
      clearStoreInLocalStroage();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  if(!currentUser){
    navigate("/")
  }
  return (
    <div className="flex justify-between items-center mx-auto mt-3  container-box">
      <span className="h-14 w-40">
        <Link to="/sport-card-list">
          <Logo />
        </Link>
      </span>
      {currentUser != null ? (
        <>
          <div className="flex gap-4">
            {/* <img
              src="https://www.citypng.com/public/uploads/preview/download-profile-user-round-purple-icon-symbol-png-11639594314uv1zwqsazt.png"
              alt=""
              className="h-[40px] rounded-[50%]"
            />
            <span>
              {currentUser?.username || "User"}{" "}
            </span> */}
            <div className="bg-[#00000029] top-[50px] bg-[#00D62B] py-2 px-4  text-white  rounded-md cursor-pointer">
              <span onClick={handleLogout}>Logout</span>
              {/* <span>Settings</span> */}
            </div>
          </div>
        </>
      ) : (
        <div className="flex gap-x-3 items-center">
          <Link
            to="/login"
            className="border-[1px] w-[100px] text-center border-[#0033FF] text-[#0033FF]
            bg-white rounded-[4px] px-2 py-2 hover:text-white hover:bg-[#0033FF]"
          >
            Login
          </Link>
          <Link
            to="/signup"
            className="border-[1px] w-[100px] text-center border-[#0033FF] text-white 
       bg-[#0033FF] rounded-[4px] px-2 py-2 hover:text-[#0033FF] hover:bg-white"
          >
            Sign Up
          </Link>
        </div>
      )}
    </div>
  );
}
