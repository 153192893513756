import React from "react";
import Header from "../../components/Header";
import AddAd from "./AddAd";
import AdCard from "./AdCard";

const Home = () => {
  return (
    <>
      <Header />
      <AddAd />
    </>
  );
};

export default Home;

{
  /* <AdCard
        title=""
        company=""
        duration=""
        price=""
        description=""
        position=""
        redirectionURL=""
        image=""
      /> */
}
