import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { GrView, GrDuplicate } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import newRequest from "../utils/newRequest";

import { urls } from "../utils/urls";
import HomeScrapperNavigator from "../Scrapper/components/ButtonScrapper";
import ReactPaginate from "react-paginate";

const NewsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsPerPages, setItemsPerPages] = useState(10);


  useEffect(() => {
    const fetchCards = async () => {
      try {
        setLoading(true);
        const response = await newRequest.get(`admin/contact/get`);
        console.log(response.data);
        setData(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchCards();

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      navigate("/");
    }
  }, []);

  // const deleteSportCard = async (id) => {
  //   try {
  //     setLoading(true);
  //     setData((prev) => {
  //       return prev.filter((item) => item._id !== id);
  //     });
  //     const response = await newRequest.delete(`${urls.deleteNewRelease}${id}`);
  //     console.log(response.data);
  //     setLoading(false);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  // const handleDuplicate = async (id) => {
  //   // console.log(id);
  //   try {
  //     const response = await newRequest.post(
  //       `${urls.duplicateNewRelease}${id}/duplicate`
  //     );
  //     console.log(response.data);
  //     const duplicatedSportCard = response.data;
  //     setData((prev) => [...prev, duplicatedSportCard]);
  //     alert("Sport card duplicated successfully!");
  //   } catch (error) {
  //     console.error(error);
  //     alert("Error duplicating sport card.");
  //   }
  // };

  // search bar
  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const offset = pageNumber * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
  const startIndex = pageNumber * itemsPerPage + 1;
  const endIndex = Math.min(
    (pageNumber + 1) * itemsPerPage,
    filteredData.length
  );
  return (
    <>
      <Header />
      <div className="w-full">
        <HomeScrapperNavigator />
      </div>
      <div className="w-full flex gap-12">
        <div className="w-[15%]">
          <nav
            id="sidenav-1"
            className="absolute mt-[10rem] left-0 top-0 z-[1035] h-full w-60 -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
            data-te-sidenav-init
            data-te-sidenav-hidden="false"
            data-te-sidenav-position="absolute"
          >
            <ul className="relative m-0 list-none">
              <li className="relative pb-4">
                <Link
                  className="flex h-14 items-center font-bold px-6 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear bg-[#423D68] text-white "
                  data-te-sidenav-link-ref
                >
                  <span>Contact Userlist</span>
                </Link>
              </li>
              <li className="relative">
                <ul>
                  <li className="relative">
                    <Link
                      to="/sport-card-list"
                      className="flex h-12 font-bold  cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-4 text-[0.90rem] outline-none transition ease-linear hover:bg-slate-50  hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/calendar"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Add New Release
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/registered-users"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Registered Users
                    </Link>
                  </li>
                  <li className="relative">
                    <Link
                      to="/add-new-list"
                      className="flex h-12 font-bold cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.90rem] outline-none transition duration-300 ease-linear hover:bg-slate-50 hover:text-inherit hover:outline-none focus:bg-slate-50 focus:text-inherit focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    >
                      Add New List
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        {loading && <div className="w-[80%] overflow-hidden"> <Loader /> </div>}
        {!loading &&<div className="w-[80%] overflow-hidden">
          
          <div className="flex justify-between flex-wrap items-center">
            <button
              className="mr-[5px] px-2 py-2  text-white"
              onClick={() => navigate("/add-calendar")}
            ></button>
            <div>
              <input
                type="text"
                className="border rounded p-2 w-[20rem]"
                placeholder="Search Filter"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <table className="border-collapse mt-3 py-2 mb-10 shadow-md rounded-md">
            <thead className="bg-[#423D68] text-white font-bold">
              <tr>
                <th className=" text-left px-6 py-4">#</th>
                <th className=" text-left px-6 py-4">Name</th>
                <th className=" text-left px-6 py-4">Phone</th>
                <th className=" text-left px-6 py-4">Email</th>
                <th className=" text-left px-6 py-4">Message</th>
                {/* <th className=" text-left px-6 py-4">Team</th> */}
                {/* <th className=" text-left px-6 py-4">Price</th> */}
                {/* <th className=" text-left px-6 py-4">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {data ? (
                currentPageData?.map((item, index) => (
                  <tr key={item._id}>
                    <td className="w-[20px] text-left px-6 py-4 font-bold">
                      {startIndex + index}
                    </td>
                    <td className="w-[150px] text-left px-6 py-4 font-bold">
                      {item?.name}
                    </td>
                    <td className="w-[150px] text-left px-6 py-4 font-bold">
                      {item.phone}
                    </td>
                    <td className="w-[150px] text-left px-6 py-4 font-bold">
                      {item.email}
                    </td>
                    <td className="text-left px-6 py-4">{item.message}</td>
                    {/* <td className="text-left  pl-6 py-6 font-bold flex gap-4  ">
                    <Link
                      to={`/add-calendar/edit/${item._id}`}
                      className="link"
                    >
                      <FaEdit size={25} className="text-[blue]" />
                    </Link>
                    <Link to={`/sport-card/${item._id}`} className="link">
                      
                      <GrView className="text-[gray]" size={25} />
                    </Link>

                    <RiDeleteBin5Line
                      className="text-[red] cursor-pointer"
                      size={25}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this sport card?"
                          )
                        ) {
                          deleteSportCard(item._id);
                          window.alert("Sport card deleted successfully.");
                        }
                      }}
                    />
                    <button
                      className="text-blue cursor-pointer"
                      onClick={() => handleDuplicate(item._id)}
                    >
                      <GrDuplicate />
                    </button>
                  </td> */}
                  </tr>
                ))
              ) : (
                <h1>No Record Found</h1>
              )}
            </tbody>
          </table>
          <div class="flex items-center justify-end ">
              <div class="bg-gray-200 p-1 rounded-lg shadow-lg mt-2">
                <input
                  type="number"
                  id="items-per-page"
                  placeholder="10 Items"
                  class="w-32 px-2 py-1 mr-2 rounded leading-3 bg-gray-100 focus:outline-none focus:bg-white"
                  onChange={(event) => setItemsPerPages(parseInt(event.target.value))}
                />
                <button 
                onClick={() => {
                  console.log(itemsPerPages)
                  setItemsPerPage(itemsPerPages)
                  setPageNumber(0)
                  // setItemsPerPage(itemsPerPages)
                }}
                class="px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                  Show
                </button>
              </div>
              <div className="mb-3">
              <ReactPaginate
                forcePage = {pageNumber}
                pageCount={Math.ceil(data.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination flex justify-end"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
              </div>
            </div>
        </div>}
      </div>
    </>
  );
};

export default NewsList;
