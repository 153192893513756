import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function RichTextEditor({ onChange, value }) {
  const handleDescriptionChange = (event, editor) => {
    const description = editor.getData();
    onChange(description);
  };

  // const editorConfiguration = {
  //   toolbar: {
  //     items: [
  //       "heading",
  //       "|",
  //       "bold",
  //       "italic",
  //       "underline",
  //       "strikethrough",
  //       "|",
  //       "bulletedList",
  //       "numberedList",
  //       "|",
  //       "link",
  //       "|",
  //       "undo",
  //       "redo",
  //     ],
  //   },
  // };
  const editorConfiguration = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "link",
        "|",
        "undo",
        "redo",
      ],
    },
    // Configure the link plugin to open links in a new tab
    link: {
      addTargetToExternalLinks: true,
    },
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      config={editorConfiguration}
      onChange={handleDescriptionChange}
    />
  );
}

export default RichTextEditor;
